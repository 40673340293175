import { bpHelper } from '../../components/utils';

document.arrive('.page-scroller', (element) => {
  $(element).on('click', () => {
    const $scrollTarget = $('#scroll-target');
    if ($scrollTarget.length !== 0) {
      const scrollOffset = bpHelper.isMatching('md', true) ? ($scrollTarget.offset().top - 20) : 0;
      $(window).scrollTop(scrollOffset);
    }
  });
});
