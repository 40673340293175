class DistanceSlider {
  constructor(input) {
    this.$input = $(input);
    this.slider = document.getElementById(this.$input.data('slider-id'));
    this.$display = $(`.${this.$input.data('display-class')}`);
    this.distance = this.$input.val();
  }

  init() {
    this.sliderInit();
    this.updateValue();
  }

  updateValue() {
    const that = this;
    this.slider.noUiSlider.on('update', function(values, handle) {
      that.$display.text(values[handle]);
      that.$input.val(values[handle]);
    });
  }

  sliderInit() {
    noUiSlider.create(this.slider, {
      handles: 1,
      animate: true,
      connect: 'lower',
      start: this.distance,
      range: {
        min: [1, 1],
        '50%': [10, 5],
        max: [50],
      },
      step: 1,
      format: {
        to: function(value) {
          return Math.trunc(value);
        },
        from: function(value) {
          return value;
        },
      },
    });
  }
}

document.arrive('.email-alert__distance-input', function(element) {
  const distanceSlider = new DistanceSlider(element);
  distanceSlider.init();
});
