import 'arrive/src/arrive';
import { bpHelper } from '../../components/utils';

const breakpoints = bpHelper.getBreakpoints();
const helper = bpHelper;
const listBp = [];
let bp;
for (bp in breakpoints) {
  listBp.push(bp);
}

function shoudTrack(event) {
  let currentTarget = (event.srcEvent && event.srcEvent.target) || event.target;
  let shouldStopLoop = false;
  while (currentTarget) {
    if (currentTarget.classList.contains('prevent_accordion')) {
      return false;
    }
    if (listBp.some(function(breakpoint) {
      return currentTarget.classList.contains('prevent_' + breakpoint + '_accordion') && helper.isMatching(breakpoint);
    })) {
      return false;
    }
    if (shouldStopLoop) {
      break;
    }
    currentTarget = currentTarget.parentElement;
    shouldStopLoop = currentTarget.classList.contains('accordion');
  }
  return true;
}

const showCollapse = function(event) {
  if (shoudTrack(event)) {
    $(this).parent().addClass('accordion-item--active');
  } else {
    return false;
  }
};
const hideCollapse = function(event) {
  if (shoudTrack(event)) {
    $(this).parent().removeClass('accordion-item--active');
    event.stopPropagation();
  } else {
    return false;
  }
};

const trackAccordionCollapse = function(element) {
  const $element = $(element);
  $element.off('show.bs.collapse', showCollapse);
  $element.on('show.bs.collapse', showCollapse);

  $element.off('hide.bs.collapse', hideCollapse);
  $element.on('hide.bs.collapse', hideCollapse);

  // // tentative pour scroll auto vers l'accordion actif
  // // si implémenté un jour, faire attention à premièrement ne le faire que sur une classe spécifique d'accordion, et ensuite bien tester sur les accordions imbriqués entre eux
  // const shownCollapse = function (event) {
  //     if (shoudTrack(event)) {
  //
  //         var header = $(this).parent()
  //         console.log(header)
  //         $('html, body').animate({
  //             scrollTop: header.offset().top
  //         }, 0);
  //
  //     } else {
  //         return false;
  //     }
  // };
  //
  // $('.accordion-collapse').off('shown.bs.collapse', shownCollapse);
  // $('.accordion-collapse').on('shown.bs.collapse', shownCollapse);
};

// document.addEventListener('turbo:load', trackAccordion);
// document.addEventListener('turbo:frame-load', trackAccordion);
document.arrive('.accordion-collapse', trackAccordionCollapse);
