/**
 * Intercepts all events on an element and resolve them before executing a callback
 * @param element {HTMLElement}
 * @param events {Array}
 * @param callback
 * @param callbackParams {object}
 */
const eventsInterceptor = (element, events, callback, callbackParams = {}) => {
  const eventPromise = new Promise((resolve) => {
    events.forEach((eventName) => {
      element.addEventListener(eventName, () => {
        resolve();
      });
    });
  });

  // Wait for all event Promises to resolve
  eventPromise.then(() => {
    if (callbackParams.length > 0) {
      callback(callbackParams);
    } else {
      callback();
    }
  });
};

export { eventsInterceptor };
