document.addEventListener('turbo:load', function() {
  $('.rating-slider').owlCarousel({
    center: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 3,
      },
    },
    loop: true,
    margin: 30,
    dots: false,
    nav: true,
    autoHeight: false,
    slideTransition: '',
    navSpeed: 1000,
    navText: [$('.rating-slider-left'), $('.rating-slider-right')],
  });
});
