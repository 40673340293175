
const initInputGroup = function(item) {
  // set focus to input_group
  $(item).focus(function(e) {
    $(this).parent().addClass('input-group-focus');
  }).focusout(function(e) {
    $(this).parent().removeClass('input-group-focus');
  });
};

document.arrive('.input-group > input', initInputGroup);

const initInputGroupSubmit = function(item) {
  const form = $(item).parents('form').first();
  function showInputWarning(event) {
    $(event.target).find('.submit-input-warning').show();
  }

  $(form).off('change', showInputWarning);
  $(form).on('change', showInputWarning);

  function disableInputSubmitButton(event) {
    const button = $(event.target).find('.submit-input-button');
    $(button).addClass('btn--disabled');
  }
  $(form).off('submit', disableInputSubmitButton);
  $(form).on('submit', disableInputSubmitButton);
};

document.arrive('.input-group .submit-input-button', initInputGroupSubmit);
