import { initMarketplaceSearch } from './pages/search';
import './flyer';
import './pages/home';
import './identity_document';
import './service/email_alerts';
import './guide';
import './pictures';
import './minimal_length';
import './listing_tab';

global.initMarketplaceSearch = initMarketplaceSearch;
global.initMapWithOneMarker = (options) => import('./map/map_with_one_marker').then(module => module.initMapWithOneMarker(options));
global.initMapWithTwoMarkers = (options) => import('./map/map_with_two_markers').then(module => module.initMapWithTwoMarkers(options));
