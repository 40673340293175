import * as CookieConsent from 'vanilla-cookieconsent';
// import 'vanilla-cookieconsent/dist/cookieconsent.css';
import en from '../locales/en.json';
import fr from '../locales/fr.json';
import es from '../locales/es.json';
import it from '../locales/it.json';
import de from '../locales/de.json';
import nl from '../locales/nl.json';

document.addEventListener('turbo:load', () => {
  // This is a hack to bypass the extension I don't care about cookies
  setTimeout(() => {
    const cookieConsentElement = document.getElementById('cc-main');
    if (cookieConsentElement && window.getComputedStyle(cookieConsentElement, null).display === 'none') {
      cookieConsentElement.style.setProperty('display', 'inherit', 'important');
      cookieConsentElement.style.setProperty('height', 'auto', 'important');
      cookieConsentElement.style.setProperty('z-index', '2147483647', 'important');
      cookieConsentElement.style.setProperty('visibility', 'visible', 'important');
      cookieConsentElement.style.setProperty('width', 'auto', 'important');
      cookieConsentElement.style.setProperty('overflow', 'visible', 'important');
    }
  }, 2000);

  // we need to remove _ccRun from the window as it is the variable use by cookie consent
  // and if not removed CookieConsent will not work correctly with Turbo...
  delete window._ccRun;

  CookieConsent.run({
    root: '#cookie-consent',
    cookie: {
      name: '_cookie_consent',
      expiresAfterDays: acceptType => {
        return acceptType === 'all' ? 365 : 182;
      },
    },
    disablePageInteraction: true,
    guiOptions: {
      consentModal: {
        layout: 'cloud',
        position: 'bottom center',
        equalWeightButtons: false,
      },
      preferencesModal: {
        layout: 'box',
        equalWeightButtons: false,
      },
    },
    categories: {

      necessary: {
        enabled: true,
        readOnly: true,
      },

      analytics: {
        autoClear: {
          cookies: [
            {
              name: /^_ga/, // regex: delete all cookies starting with '_ga'
              // domain: 'nounou-top.fr', // domain only used for dev else it won't work
            },
            {
              name: '_fbp',
              // domain: 'nounou-top.fr',
            },
            {
              name: 'FCNEC',
              // domain: 'nounou-top.fr',
            },
          ],
          reloadPage: true,
        },
      },

      social: {
        autoClear: {
          cookies: [
            {
              //  cookie for sharethis
              name: 'fpestid',
            },
          ],
          reloadPage: true,
        },
      },

      advertising: {
        autoClear: {
          // cookies below belongs to adsense
          cookies: [
            {
              name: '__eoi',
              // domain: 'nounou-top.fr',
            },
            {
              name: '__gads',
              // domain: 'nounou-top.fr',
            },
            {
              name: '__gpi',
              // domain: 'nounou-top.fr',
            },
          ],
          reloadPage: true,
        },
      },
    },

    language: {
      autoDetect: 'document',
      default: 'fr',
      translations: {
        en: en.en.js.cookies,
        fr: fr.fr.js.cookies,
        de: de.de.js.cookies,
        es: es.es.js.cookies,
        it: it.it.js.cookies,
        nl: nl.nl.js.cookies,
      },
    },

    onFirstConsent: ({ cookie }) => {
      initiateCookieConsentManualModal();
      logCookieConsent();
      location.reload();
    },

    onChange: ({ cookie, changedCategories }) => {
      logCookieConsent();
      location.reload();
    },

    onConsent: () => {
      updateGoogleConsentMode();
    },
  });

  initiateCookieConsentManualModal();
});

/**
 * Consent has already been given by the user so we give them the option to change their
 * consent with the modal preferences
 */
const initiateCookieConsentManualModal = () => {
  if (CookieConsent.validConsent()) {
    document.arrive('.settings-cookie-management', { existing: true, onceOnly: true }, function(element) {
      element.style.display = 'inherit';
      element.addEventListener('click', () => {
        CookieConsent.showPreferences();
      });
    });
  }
};

/**
 * This is used to update the google consent mode and it is paired with google tag manager
 * Careful with this implementation as it is not a clean one. Even if the user has denied analytics
 * we are enabling the tag manager, which is analytics in our case, to communicate the consent and
 * then removing it.
 *
 * https://developers.google.com/tag-platform/security/guides/consent?consentmode=basic
 */
const updateGoogleConsentMode = () => {
  const updatedPreferences = {
    ad_storage: 'denied', // adsense accepted
    ad_user_data: 'denied',
    ad_personalization: 'denied',
    analytics_storage: 'denied', // if tag manager is accepted
  };

  const preferences = CookieConsent.getUserPreferences();
  const analyticsGranted = preferences.acceptedCategories.includes('analytics');
  if (analyticsGranted) {
    updatedPreferences.analytics_storage = 'granted';
  }

  if (preferences.acceptedCategories.includes('advertising')) {
    updatedPreferences.ad_storage = 'granted';
    updatedPreferences.ad_user_data = 'granted';
    updatedPreferences.ad_personalization = 'granted';
  }

  // eslint-disable-next-line no-undef
  gtag('consent', 'update', updatedPreferences);
};

const logCookieConsent = () => {
  const cookie = CookieConsent.getCookie();
  const preferences = CookieConsent.getUserPreferences();

  const userConsent = {
    consentId: cookie.consentId,
    acceptType: preferences.acceptType,
    acceptedCategories: preferences.acceptedCategories,
    rejectedCategories: preferences.rejectedCategories,
  };

  fetch('/cookie_consent/update', {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content,
    },
    body: JSON.stringify(userConsent),
  });
};
