
// Example of use
// $.cachedScript( "ajax/test.js" ).then(function( textStatus ) {
//   console.log( textStatus );
// }).error(function(error){ console.error( error ) });

const LOADED_SCRIPT = [];
jQuery.cachedScript = function(url, options) {
  if (LOADED_SCRIPT[url] === 'success') {
    return new Promise((resolve, reject) => { resolve('success'); });
  }
  if (typeof LOADED_SCRIPT[url] === 'string') {
    return new Promise((resolve, reject) => { reject(LOADED_SCRIPT[url]); });
  }
  // si pas success c'est un promise
  if (LOADED_SCRIPT[url]) {
    return LOADED_SCRIPT[url];
  }
  LOADED_SCRIPT[url] = new Promise((resolve, reject) => {
    // Allow user to set any option except for dataType, cache, and url
    options = $.extend(options || {}, {
      dataType: 'script',
      cache: true,
      success: function(data) { resolve('success'); },
      error: function(error) { reject(error); },
      url,
    });
    // Use $.ajax() since it is more flexible than $.getScript
    jQuery.ajax(options);
  }).then(() => { LOADED_SCRIPT[url] = 'success'; }).catch((error) => { console.error(error); LOADED_SCRIPT[url] = error; });
  return LOADED_SCRIPT[url];
};
