
document.arrive('.contract-editor #minimum_volume_hours,.contract-editor #minimum_volume_minutes', function(element) {
  function checkMinimumVolume() {
    const hours = $('.contract-editor #schedule_contract_minimum_volume_hours').val();
    const minutes = $('.contract-editor #schedule_contract_minimum_volume_min').val();
    const time = hours * 100 + minutes;
    if (time > 4800) {
      $('.time-slot-edit__max-weekly-exceeded-warning').show();
    } else {
      $('.time-slot-edit__max-weekly-exceeded-warning').hide();
    }
  }
  $(element).on('change', checkMinimumVolume);
  $('.contract-editor #schedule_contract_minimum_volume_min').on('change', checkMinimumVolume);
});
