import './bootstrap-custom';
import '../components/is_in_viewport';
import './components/accordion';
import '../components/offcanvas';
import './components/scroll_to';
import './components/pagination_scroll_to';

// Modal
import { showModalAjax, hideModalManually, closeAllModal, openRemoteModal } from './components/modal';
import { avatarUploader } from './components/avatars-upload';

// Form
import './inputs';

import './components/install_app';

// Navigation
import './components/link';
import './components/dotslink';
import './components/nav';
import './components/popup_link';
import './components/link_obfuscate';

$.fn.fullCalendar = function(options) {
  const context = this;
  import('../vendors/fullcalendar').then((module) => {
    module.init(jQuery);
    $.fn.fullCalendar.call(context, options);
  });
};

global.showModalAjax = showModalAjax;
global.hideModalManually = hideModalManually;
global.close_all_modal = closeAllModal;
global.openRemoteModal = openRemoteModal;
global.avatarUploader = avatarUploader;
global.initTimeRangeCalendar = () => import('./components/timerange_calendar').then(module => module.initTimeRangeCalendar());
global.initTimesheetsCalendar = () => import('./components/timesheets_calendar').then(module => module.initTimesheetsCalendar()); ;

// console.log("load design system");
