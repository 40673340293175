import { eventsInterceptor } from '../../components/events';

function NotificationSetting(element, event) {
  this.$element = $(element);
  this.event = event;
  this.$parent = this.$element.parent();
  this.$spinner = this.$element.closest('.notification-setting__checkbox').children('.notification-setting__spinner');

  this.blockElement = function() {
    this.$parent.append("<div class='notification-setting__click-blocker'></div>");
    this.$element.attr('disabled', true);
    this.$spinner.show();
  };

  this.unblockElement = function() {
    this.$element.removeAttr('disabled');
    this.$spinner.hide();
    this.$parent.find('.notification-setting__click-blocker').remove();
  };

  this.changeNotificationSetting = function() {
    this.blockElement();
    eventsInterceptor(element, ['change'], () => {
      $.ajax({
        url: '/notification_settings',
        type: 'PATCH',
        data: {
          type: this.$element.data('type'),
          setting: this.$element.data('setting'),
          value: this.$element.is(':checked'),
        }
      }).done( () => {
        this.unblockElement();
      });
    });
  };

  this.changeSubscriptionSetting = function() {
    this.blockElement();

    eventsInterceptor(element, ['change'], () => {
      $.ajax({
        type: 'PATCH',
        url: '/push_notification_subscriptions',
        data: {
          id: this.$element.data('id'),
          active: this.$element.is(':checked'),
        },
      }).done(() => {
        this.unblockElement();
      });
    });
  };

  this.toggleBirthdayNotification = () => {
    this.blockElement();

    eventsInterceptor(element, ['change'], () => {
      $.ajax({
        type: 'PATCH',
        url: '/birthdays_alert/toggle_activation',
      }).done(() => {
        this.unblockElement();
        $('.notification-setting__birthday-notification-setting').slideToggle('slow', () => {
          const $masonryElement = $('.notification-setting__masonry');
          $masonryElement.masonry('reloadItems');
          $masonryElement.masonry('layout');
        });
      });
    });
  };
}

document.addEventListener('turbo:load', function() {
  $('.notification-setting__input').click(function(e) {
    const notificationSetting = new NotificationSetting(this, e);
    notificationSetting.changeNotificationSetting();
  });

  $('.notification-setting__input-subscription').click(function(e) {
    const notificationSetting = new NotificationSetting(this, e);
    notificationSetting.changeSubscriptionSetting();
  });

  $('.notification-setting__birthday-notification-activation').click(function(e) {
    const notificationSetting = new NotificationSetting(this, e);
    notificationSetting.toggleBirthdayNotification();
  });
});
