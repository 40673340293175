function toggleCardDimmer(cardLink) {
  $(cardLink).on('click', function() {
    const dimmer = $(this).parent().find('.dimmer');
    dimmer.css('visibility', 'inherit');
    document.addEventListener('turbo:before-fetch-response', function() {
      dimmer.css('visibility', 'hidden');
    }, { once: true });
  });
}

function launchLoader(event) {
  const dimmer = $(event.target).parent().find('.dimmer');
  dimmer.css('visibility', 'inherit');
  document.addEventListener('turbo:before-fetch-response', function() {
    dimmer.css('visibility', 'hidden');
  }, { once: true });
}

function dateShowLoaderOnClick(event) {
  dateShowLoader($(event.target));
//   test
}

function dateShowLoader($element) {
  const $parent = $element.parents('.page-action-header');
  $parent.find('.page-action-header__date').css('visibility', 'hidden');
  $parent.find('.page-action-header__loading').css('visibility', 'inherit');
}

document.arrive('.show-dimmer', function(element) {
  const $element = $(element);
  $element.off('click', launchLoader);
  $element.on('click', launchLoader);
});

document.arrive('.page-action-change-date', function(element) {
  const $element = $(element);
  $element.off('click', dateShowLoaderOnClick);
  $element.on('click', dateShowLoaderOnClick);
});

$(document).on('turbo:load', function() {
  toggleCardDimmer($('.card-block a.main-link:not([target=_blank]):not(.display-content)'));
});

document.arrive('.card-block a.main-link:not([target=_blank]):not(.display-content)', function(element) {
  toggleCardDimmer(element);
});

export { dateShowLoader };
