import { Controller } from '@hotwired/stimulus';

const categoryActiveClass = 'category-search__category-list-item--selected';
const subtypeActiveClass = 'icon-list__item--selected';
const subtypeListActiveClass = 'search-category-form__subtype-list--show';

export default class extends Controller {
  static targets = [
    'category',
    'subtype',
    'form',
    'categoryModal',
    'categoryInput',
    'subcategoryInput',
    'employerTypeText',
    'employeeTypeText',
    'subtypeList',
    'subtypeInput',
    'typeInput',
  ];

  static values = {
    filters: Object,
  };

  connect() { }

  transitionCategory(event) {
    const params = event.params;

    // return if is the active category
    if (event.params.category === this.categoryInputTarget.value && this.currentEmployerText === params.employerText && this.currentEmployeeText === params.employeeText) {
      return false;
    }
    this.currentEmployerText = params.employerText;
    this.currentEmployeeText = params.employeeText;

    // Used by marketplace--category-controller to handle category and background change behind the modal
    this.dispatch('transitionCategory', { detail: { category: params.category } });

    this.setCategory(params.category);
    this.setSubCategory();

    this.removeCategoryActiveClass();
    this.addCategoryActiveClass();

    this.changeTypeText();

    this.transitionSubtypes();
  }

  subtypeSelect(event) {
    this.removeSubtypeActiveClass();
    event.currentTarget.classList.add(subtypeActiveClass);
    this.subtypeInputTarget.value = event.params.subtype;
    this.filtersValue = event.params.filters;
  }

  removeSubtypeActiveClass() {
    this.subtypeTargets.forEach(target => {
      target.classList.remove(subtypeActiveClass);
    });
    this.subtypeValue = '';
    this.subtypeInputTarget.value = '';
    this.filters = {};
  }

  removeCategoryActiveClass() {
    this.categoryTargets.forEach(target => {
      target.classList.remove(categoryActiveClass);
    });

    this.categoryModalTargets.forEach(target => {
      target.classList.remove(categoryActiveClass);
    });
  }

  activeCategory() {
    return $(`.category-search__category-list-item-${this.categoryInputTarget.value}`);
  }

  addCategoryActiveClass() {
    this.activeCategory().addClass(categoryActiveClass);
  }

  setCategory(category) {
    if (this.categoryInputTarget.value !== category) this.removeSubtypeActiveClass();
    this.categoryInputTarget.value = category;
  }

  setSubCategory() {
    const typeValue = this.typeInputTargets.find((typeInput) => { return typeInput.checked; }).value;
    this.subcategoryInputTarget.value = this.categoryInputTarget.value + '_' + typeValue;
  }

  changeTypeText() {
    this.employerTypeTextTarget.innerHTML = this.currentEmployerText;
    this.employeeTypeTextTarget.innerHTML = this.currentEmployeeText;
  }

  transitionSubtypes() {
    this.subtypeListTargets.forEach(target => {
      target.classList.remove(subtypeListActiveClass);
    });

    $(`.search-category-form__subtype-list-${this.categoryInputTarget.value}`).addClass(subtypeListActiveClass);
  }

  setCategoryFilters() {
    // Get the form element
    const form = this.formTarget;
    const filters = this.filtersValue;

    // Add additional data to the form data
    Object.keys(filters).forEach(function(key) {
      const input = document.createElement('input');
      input.setAttribute('type', 'hidden');
      input.setAttribute('name', 's[' + key + ']');
      input.setAttribute('value', filters[key]);
      form.appendChild(input);
    });
  }

  submitForm(event) {
    this.setCategoryFilters();
    // if(this.typeInputTarget.value === 'offer' && this.subcategoryInputTarget.value === 'child_service') return false;
    this.formTarget.requestSubmit();
  }
}
