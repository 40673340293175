import { Controller } from '@hotwired/stimulus';
import Modal from 'bootstrap/js/dist/modal';
import { closeAllModal } from '../design_system/components/modal';

// this class handle browser "previous" event and dispatch it on last modal shown
class PopStateHandler {
  constructor() {
    this.itemsPool = [];
    this.bypassBack = false;
    this.setupPopStateListener();
  }

  // Adds an item to the pool
  addItem(item) {
    this.itemsPool.push(item);
    window.history.pushState({ popStateHandler: true }, '');
  }

  // Removes the last item from the pool and returns it
  removeLastItem() {
    return this.itemsPool.pop();
  }

  // Simulates a back navigation in the browser
  simulateBack() {
    if (window.history.state && window.history.state.popStateHandler) {
      // console.log("simulateBack.");
      this.bypassBack = true; // Flag to bypass certain actions during automated back navigation
      window.history.back(); // Triggers the browser to go back in history
    }
  }

  // Removes a specific item from the pool, if it exists
  removeItem(item) {
    const index = this.itemsPool.indexOf(item); // Find the index of the item
    if (index >= 0) {
      this.itemsPool.splice(index, 1); // Remove item if found
      return true; // Return true to indicate successful removal
    }
  }

  /**
   * Handles the 'popstate' event triggered by browser navigation actions.
   * @param {PopStateEvent} event - The event object passed by the 'popstate' event.
   */
  handlePopState(event) {
    // Check if the back action should be bypassed (used to prevent default processing in specific scenarios)
    if (this.bypassBack) {
      this.bypassBack = false; // Reset the bypass flag
      // console.log("handlePopState bypassed."); // Debugging statement
      return; // Exit the function to skip processing this popstate event
    }

    // Check if there are items left in the pool to process
    if (this.itemsPool.length > 0) {
      event.preventDefault(); // Prevent the default browser handling of the popstate event
      const lastItem = this.removeLastItem(); // Remove the last item from the pool
      // console.log("Processed on lastItem :", lastItem); // Log processing action for debugging
      lastItem.handlePopState(); // Call the handlePopState method on the lastItem, assuming it exists
      // Perform additional actions with lastItem if needed (custom logic or cleanup)
    } else {
      // console.log("No items left to process."); // Log when no items are left to handle
    }
  }

  /**
   * Sets up the popstate event listener for the window object.
   * This method is crucial for enabling the class to respond to browser navigation events.
   */
  setupPopStateListener() {
    // Bind the handlePopState method to the current instance.
    // This is necessary to ensure that 'this' within the handlePopState method
    // refers to the current instance of the class, not to the window object
    // which triggers the event. Binding creates a new function where 'this' is
    // permanently set to the current instance, regardless of how the function is called.
    this.ownHandlePopState = this.handlePopState.bind(this);

    // Add the bound function as an event listener for the 'popstate' event on the window object.
    // The 'popstate' event is fired whenever the active history entry changes.
    // By attaching this event listener, the class can execute custom logic
    // defined in handlePopState whenever the user navigates to a new state.
    window.addEventListener('popstate', this.ownHandlePopState);
  }
}
const popStateHandler = new PopStateHandler();
export default class extends Controller {
  static values = {
    showOnDisplay: Boolean,
    onClose: String,
    closeOtherModals: Boolean,
  };

  connect() {
    this.modal = new Modal(this.element);
    const that = this;
    this.element.addEventListener('hide.bs.modal', function(event) {
      that.hidden(event);
    });
    this.closeOtherModals();
    if (this.showOnDisplayValue) {
      this.show();
    }
  }

  disconnect() {
    if (this.isOpen()) {
      this.hide();
    }
  }

  show() {
    this.modal.show();
    popStateHandler.addItem(this);
  }

  hide() {
    this.modal.hide();
  }

  hidden(event) {
    // if the item is removed from the handler ( ie not closed using history nav )
    if (popStateHandler.removeItem(this)) {
      // simulate back in order to keep historic uptodate
      // FIX : need to fix this call, if onclose call a refresh or Turbo.visit() it fails ...
      // popStateHandler.simulateBack();
    }
    if (this.hasOnCloseValue && typeof window[this.onCloseValue] === 'function') {
      window[this.onCloseValue]();
    }
  }

  hideBeforeRender(event) {
    if (this.isOpen()) {
      event.preventDefault();
      this.element.addEventListener('hidden.bs.modal', event.detail.resume);
      this.hide();
    }
  }

  isOpen() {
    return this.element.classList.contains('show');
  }

  closeOtherModals() {
    if (this.closeOtherModalsValue) {
      closeAllModal();
    }
  }

  handlePopState() {
    if (this.isOpen()) {
      this.hide();
    }
  }
}

const reloadPage = function(hardRefresh = false) {
  if (hardRefresh) {
    window.location.reload(hardRefresh);
  } else {
    Turbo.visit(window.location.pathname);
  }
};
global.reloadPage = reloadPage;
