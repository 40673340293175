const avatarUploader = function(frame) {
  let boundx;
  let displayBoundX;
  // let boundy;
  let displayBoundY;

  const $frame = frame;
  const $avatarForm = $frame.find('form');
  $avatarForm.on('click', '#rotate_left', function() {
    rotatePic('left');
    return false;
  });
  $avatarForm.on('click', '#rotate_right', function() {
    rotatePic('right');
    return false;
  });
  $avatarForm.on('change', '#photo-select-input', function() {
    readUrl(this);
  });

  loadCropbox();

  function readUrl(input) {
    $frame.find('#submit_photo').show();
    if (input.files && input.files[0]) {
      $frame.find('#avatar_actions').show();
      $frame.find('#photo-illustration').hide();

      const reader = new FileReader();
      reader.onload = function(e) {
        $frame.find('#avatar-upload-preview').attr('src', e.target.result);
      };
      reader.readAsDataURL(input.files[0]);
      $frame.find('#rotate_angle').val(0);
    }
  };

  function rotatePic(direction) {
    const $rotateAngle = $frame.find('#rotate_angle');
    const angle = (parseInt($rotateAngle.val()) + (direction === 'left' ? -90 : 90)) % 360;

    $rotateAngle.val(angle);
    const cssInstruction = 'rotate(' + angle + 'deg)';
    $frame.find('#avatar-upload-preview').css({
      'transition-duration': '0.8s',
      'transition-property': 'transform',
      '-webkit-transform': cssInstruction,
      '-moz-transform': cssInstruction,
      transform: cssInstruction, /* For modern browsers(CSS3)  */
    });
    return false;
  };

  function updatePreview(coords) {
    if (parseInt(coords.w) > 0) {
      const ratio = boundx / displayBoundX;
      const rx = 150 / coords.w;
      const ry = 150 / coords.h;
      $frame.find('#avatar-preview').css({
        width: Math.round(rx * displayBoundX) + 'px',
        height: Math.round(ry * displayBoundY) + 'px',
        marginLeft: '-' + Math.round(rx * coords.x) + 'px',
        marginTop: '-' + Math.round(ry * coords.y) + 'px',
      });

      $frame.find('#crop_x').val(Math.round(coords.x * ratio));
      $frame.find('#crop_y').val(Math.round(coords.y * ratio));
      $frame.find('#crop_w').val(Math.round(coords.w * ratio));
      $frame.find('#crop_h').val(Math.round(coords.h * ratio));
    }
  };

  function loadCropbox() {
    const wWidth = $(window).width();
    let maxWidth = Math.min(wWidth, 500);
    if (wWidth >= 768 && wWidth < 992) {
      maxWidth = 450;
    }

    $frame.find('#cropbox').Jcrop({
      minSize: [150, 150],
      onChange: updatePreview,
      onSelect: updatePreview,
      addClass: 'jcrop-dark',
      aspectRatio: 1,
      boxWidth: maxWidth,
      boxHeight: maxWidth,
    }, function() {
      // Use the API to get the real image size
      const bounds = this.getBounds();
      boundx = $frame.find('img#cropbox')[0].naturalWidth;
      // boundy = $frame.find('img#cropbox')[0].naturalHeight;
      displayBoundX = bounds[0];
      displayBoundY = bounds[1];

      const ratio = displayBoundX / boundx;
      // Store the API in the jcropApi variable
      const jcropApi = this;
      if ($('#crop_h').val()) {
        const x1 = $frame.find('#crop_x').val() * ratio;
        const y1 = $frame.find('#crop_y').val() * ratio;
        const x2 = x1 + ($('#crop_w').val() * ratio);
        const y2 = y1 + ($('#crop_h').val() * ratio);
        jcropApi.animateTo([x1, y1, x2, y2]);
      } else {
        jcropApi.animateTo([200, 200, 350, 350]);
      }
    });
  };
};

export { avatarUploader };
