import { callTurboStream } from '../../components/turbo';

document.arrive('#attendance_week', function() {
  // delais due to turbo cache page
  setTimeout(() => { document.addEventListener('journeysChange', refreshWeek); });
});

document.leave('#attendance_week', function() {
  document.removeEventListener('journeysChange', refreshWeek);
});

function refreshWeek(event) {
  const timesheetResume = document.getElementById('timesheets-resume');
  if (!timesheetResume) return;
  const url = timesheetResume.dataset.child_attendance_resume_path;
  callTurboStream(url, {callback: () => {
    setTimeout(() => { $('.timesheets-calendar').fullCalendar('rerenderEvents'); }, 10);
  }});
}
