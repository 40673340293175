document.arrive("[role='tablist']", function(elementDom) {
  const element = $(elementDom);
  const items = element.find('.nav-link');
  const tabContentsId = '.tab-content#' + element[0].id + 'Content';
  element.find('.nav-link').on('click', function() {
    items.removeClass('active');
    items.attr('aria-selected', 'false');
    const navLink = $(this);
    navLink.addClass('active');
    navLink.attr('aria-selected', 'true');
    const targetId = navLink.data().bsTarget || navLink.attr('href');
    if (targetId) {
      $(tabContentsId + ' .tab-pane').removeClass('show').removeClass('active');
      $(tabContentsId + ' .tab-pane' + targetId).addClass('show').addClass('active');
    }
    // console.log("click on nav-link");
  });
});
