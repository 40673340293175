import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    domId: String,
  };

  static targets = [
    'icon',
    'content',
    'reactivationContainer',
  ];

  connect() {
    if (this.hasReactivationContainerTarget) {
      const reactivationLink = this.reactivationContainerTarget.querySelector('.reactivation-link');

      const handleClick = (event) => {
        if (event.target !== reactivationLink) {
          $(this.domIdValue).click();
        }
      };

      this.iconTarget.addEventListener('click', handleClick);
      this.contentTarget.addEventListener('click', handleClick);
    }
  }
}
