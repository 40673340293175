document.arrive('input[data-provide="phone-input"]', function(element) {
  const formats = {};
  formats.BE = '~~~~ 99 99 99';
  formats.BR = '(0) 99 9999-9999';
  formats.CA = '999 999-9999';
  formats.CH = '999 999 99 99';
  formats.DE = '~~~~~ / 999999?9';
  formats.DK = '99 99 99 99';
  formats.ES = '999.99.99.99';
  formats.FR = '09 99 99 99 99';
  formats.RE = '99 99 99 99 99';
  formats.FI = '(0) 99 999 9999';
  formats.IT = '999 999999?9';
  formats.NL = '99 999 99 99';
  formats.PT = '999 999 999';
  formats.US = '(999) 999-9999';
  formats.LU = '999 999 999';
  formats.other = '+~~~~~~?~~~~~~~~~~~~~';

  const customMask = formats.FR;

  $.mask.definitions['~'] = '[ 0123456789]';

  if (customMask.indexOf('-') >= 0) {
    $.mask.placeholder = '.';
  } else {
    $.mask.placeholder = '-';
  }
  const $input = $(element);
  const $select = $('.country-select', $input.parent());

  const onContryChange = function() {
    let value = $select.val();
    if (!formats[value]) {
      value = 'other';
    }
    setMask(formats[value]);
  };

  $select.off('change', onContryChange);
  // There are two different kinds of country select for phone, one with many countries and the other with France and
  // other only so we need to check in which case we are first because in one case this code breaks the ui
  if ($select.find('option[value="other"]').length > 0) {
    if ($input.val().startsWith('+')) {
      $select.val('other');
    } else {
      $select.val('FR');
    }
  }

  $select.on('change', onContryChange).change();

  function setMask(customMask) {
    $input.attr('placeholder', customMask.replaceAll('9', '-').replaceAll('~', ' ').replaceAll('?', ' '));
    $input.mask(customMask, {
      autoclear: false,
      completed: function() {
        $('.icon', $(this).parent()).addClass('green');
      },
    });
  };
});
