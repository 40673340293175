import { GedAttendanceManagement } from './pages/children_event_edit';
import './pages/child_payslip';
import './pages/child_report';
import './pages/child_attendances';

/**
 * To preserve the this context for a dynamic import on jquery we have to
 * use a traditional function that will get the "this" context and call the function with the correct context
 */
$.fn.vacationManagement = function() {
  const $this = this;
  import('./pages/child_vacations')
    .then(module => module.vacationManagement.call($this));
};

global.initContractArchiveForm = () => import('./pages/contract_archive').then(module => module.initContractArchiveForm());
global.initSimulationForm = () => import('./pages/payslip_simulation').then(module => module.initSimulationForm());
global.initWageCertificate = () => import('./pages/child_vacations').then(module => module.initWageCertificate());
global.GedAttendanceManagement = GedAttendanceManagement;
global.costWarningInit = () => import('./pages/journey_form').then(module => module.costWarningInit());
global.initPlanningEditModal = () => import('./pages/planning_edit_modal').then(module => module.initPlanningEditModal());
