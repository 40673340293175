import 'arrive/src/arrive';
import { randomString } from '../../components/utils';

document.arrive('.copy-button', function(element) {
  const $button = $(element);
  const $input = $button.parents('.input-group').find('input');
  const initialLabel = $button.html();

  const onClick = function() {
    $button.html($button.data().actionDoneLabel || 'Copié !');
    $input.select();
    document.execCommand('copy');
    setTimeout(function() {
      $button.html(initialLabel);
    }, 3000);
  };

  $button.off('click', onClick);
  $button.on('click', onClick);
});

// copy le text a l'interrieur de l'item
document.arrive('.copy-quick', function(element) {
  const $element = $(element);
  function copy(event) {
    const textToCopy = $element.data('to-copy') || $element.text();
    let timeout = 2500;
    let message = 'Impossible à copier. Faites un copier-coller à la main. <br />' +
        "<span style='color: red; font-weight: bold;'>Il n'est pas possible de se servir de cette fonctionalité sans localhost ou https</span>";
    if (navigator.clipboard) {
      navigator.clipboard.writeText(textToCopy);
      message = 'Copié !';
      timeout = 500;
    }
    const randomId = randomString();
    $('body').append(`<span class='copy-quick-info' id='${randomId}' style='top:${(event.clientY + 20)}px; left:${(event.clientX)}px;'>${message}</span>`);
    setTimeout(() => { $(`#${randomId}`).remove(); }, timeout);
    event.preventDefault();
    return false;
  }
  $element.off('click', copy);
  $element.on('click', copy);
});
