const updateField = function(event) {
  let validRegex;
  let regex;
  const $input = $(event.target);
  const currValue = $input.val().replace(/-+/, '-');
  $input.val(currValue);
  if ($input.hasClass('allow-negative')) {
    regex = /[^0-9-]/g;
    validRegex = /(^-?\d+)([^\d]+\d*)$/g;
  } else {
    regex = /[^0-9]/g;
    validRegex = /(^\d+)([^\d]+\d*)$/g;
  }
  if (currValue.search(regex) !== -1 || currValue.search(validRegex) !== -1) {
    $input.val(currValue.replaceAll(regex, '').replaceAll(validRegex, '$1'));
    if ($input.parent().hasClass('input-group')) {
      $input.parent().addClass('is-invalid');
    } else {
      $input.addClass('is-invalid');
    }
    setTimeout(function() {
      if ($input.parent().hasClass('input-group')) {
        $input.parent().removeClass('is-invalid');
      } else {
        $input.removeClass('is-invalid');
      }
    }, 300);
  }
};
const initializeInputOnlyInteger = function(element) {
  const $item = $(element);
  $item.off('paste keyup change', updateField);
  $item.on('paste keyup change', updateField);
};

document.arrive('input.only-integer', initializeInputOnlyInteger);
