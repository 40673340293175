import { Turbo } from '@hotwired/turbo-rails';
import { removeParamsFromUrl } from './utils';

// https://stackoverflow.com/a/75750578
Turbo.StreamActions.redirect = function() {
  const frame = this.attributes.frame.value;
  if (!frame || frame === '_top') {
    Turbo.visit(this.target);
  } else {
    const frameElement = document.querySelector('turbo-frame#' + frame);
    if (frameElement) {
      frameElement.src = this.target;
      frameElement.reload();
    } else {
      Turbo.visit(this.target);
    }
  }
};

Turbo.StreamActions.update_url = function() {
  const newUrl = new URL(this.target);
  Turbo.navigator.history.push(newUrl);
};

document.addEventListener('turbo:frame-missing', async(event) => {
  console.log('turbo frame missing');
  const { detail: { response, visit } } = event;
  event.preventDefault();
  visit(response);
});

async function hookRedirect(event) {
  const fetchResponse = event.detail.fetchResponse;
  const { url, redirected, headers } = event.detail.fetchResponse.response;
  // If the response is a redirect requesting the _top frame then replace the document.body
  // with the response
  if (redirected && url.match('turbo_frame=_top')) {
    event.preventDefault();
    const { redirected, statusCode, responseHTML } = fetchResponse;
    const response = { redirected, statusCode, responseHTML: await responseHTML };
    const urlToShow = url.replace(/(?:\?turbo_frame=_top)|(?:&turbo_frame=_top)/gm, '');
    Turbo.visit(urlToShow, { response });
  }
  // if server decide to send turbo_frame
  const frameId = headers.get('turbo_frame');
  if (frameId && frameId !== '_top' && headers.get('content-type').indexOf('vnd.turbo-stream.html') < 0) {
    event.preventDefault();
    const frame = document.getElementById(frameId);
    if (frame.delegate) {
      // load the response in the frame
      await frame.delegate.requestSucceededWithResponse({}, fetchResponse);
    }
  }
}

// TODO remove it when frame-missing event is ready
document.addEventListener('turbo:before-fetch-response', async(event) => {
  hookRedirect(event);
});
// add a specific header to be able to identify if the request come from turbo or from the browser (new tab, new session ...)
document.addEventListener('turbo:before-fetch-request', async(event) => {
  event.detail.fetchOptions.headers.turbo = 'true';
});

document.addEventListener('turbo:visit', async(event) => {
  removeParamsFromUrl(['turbo_frame', 'action_token']);
});
document.addEventListener('turbo:load', async(event) => {
  removeParamsFromUrl(['turbo_frame', 'action_token']);
});

document.addEventListener('turbo:frame-render', async(event) => {
  // console.log(" on turbo:frame-render");
  if (event.target.nodeName !== 'TURBO-FRAME') {
    return;
  }
  // https://github.com/hotwired/turbo-rails/issues/580
  event.target.src = false;
  // end of fix degue
  const fetchResponse = event.detail.fetchResponse;
  const fromUrl = window.location.href;
  if (fetchResponse.response.bodyUsed) {
    return;
  }
  // const response = fetchResponse.response.clone();
  const turboFrameId = event.target.id;
  const url = fetchResponse.response.url;

  const { redirected, statusCode, responseHTML } = fetchResponse;
  const response = { redirected, statusCode, responseHTML: await responseHTML };
  const html = await fetchResponse.response.text();

  const parseHtml = new DOMParser().parseFromString(html, 'text/html');
  const turboFrame = parseHtml.getElementById(turboFrameId);
  if (turboFrame && turboFrame.nodeName === 'TURBO-FRAME') {
    return;
  }
  // if full layout then display it, otherwise go to 500
  if (parseHtml.querySelector('html head title')) {
    // fake render the page
    Turbo.visit(url, { response });
  } else {
    const message = {
      error: "fail to load url with turbo, you should add data:{turbo\" _frame: '_top'} to the link",
      wanted_turbo_frame_id: turboFrameId,
      from: fromUrl,
      targetUrl: url,
    };
    console.error(message);
    $.ajax({ url: '/application/raise_error?error=' + JSON.stringify(message) });
    Turbo.visit(url);
  }
});

function callTurboStream(href, options = { method: 'GET', callback: null }) {
  const headers = {
    Accept: 'text/vnd.turbo-stream.html',
    'X-CSRF-Token': document.querySelector('[name=\'csrf-token\']').content,
  };
  if (options.method === 'POST' || options.method === 'PATCH') {
    headers['Content-Type'] = 'application/x-www-form-urlencoded';
  }
  const promise = fetch(href, {
    method: options.method,
    headers,
  });
  promise.then(r => r.text())
    .then(html => Turbo.renderStreamMessage(html))
    .then(param => { if (options.callback) options.callback(); });
  return promise;
}

export { callTurboStream };
// [
//     'turbo:click',
//     'turbo:before-visit',
//     'turbo:visit',
//     'turbo:submit-start',
//     'turbo:before-fetch-request',
//     'turbo:before-fetch-response',
//     'turbo:submit-end',
//     'turbo:before-cache',
//     'turbo:before-render',
//     'turbo:before-stream-render',
//     'turbo:render',
//     'turbo:load',
//     'turbo:before-frame-render',
//     'turbo:frame-render',
//     'turbo:frame-load',
//     'turbo:frame-missing',
//     'turbo:fetch-request-error',
//     'turbo:reload',
// ].forEach(function (value) {
//     document.addEventListener(value, (event) => {
//         console.log(value);
//         console.log(event);
//     })
// });
