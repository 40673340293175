import ScrollSpy from 'bootstrap/js/dist/scrollspy';

document.addEventListener('turbo:load', function() {
  if ($('body').hasClass('subscription-index')) {
    new ScrollSpy($('#subscription-main-column'), {
      target: '#subscription-lateral-column',
      smoothScroll: true,
    });
  }
});
