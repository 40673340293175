import { bpHelper } from './utils';

// le bottom menu étant en data-permanent, il ne faut pas binder les events au turbo-load sinon ils sont bindés de multiple fois
$(document).ready(function() {
  $('#mobile-menu a.item').on('click', function() {
    const that = $(this);
    if (!that.hasClass('ignore-click-status')) {
      $('#mobile-menu a.item').removeClass('clicked active');
      $(this).addClass('clicked active');
    }
    setTimeout(function() {
      that.removeClass('clicked');
    }, 600);
  });
});

function selectBottomMenu(view) {
  $('#mobile-menu a.item').removeClass('active');
  const $selectViewMobile = $('#mobile-menu' + " a.item[data-view='" + view + "']");
  $selectViewMobile.addClass('active');

  // On gère cela manuellement, compliqué en dynamique
  if (['selections', 'achievements', 'notebook_album', 'notebook_infos', 'notebook_settings'].includes(view)) {
    $('#mobile-menu a.item.more-items ').addClass('active');
  }
}

function showLoader(link, linkIcon, loader, links) {
  linkIcon.hide();
  link.append(loader);
  links.css('pointer-events', 'none');
}

function menuLoader(loaderHtml) {
  // comme au dessus, turbo:load cree plusieurs listeners sur le meme element
  $(document).arrive('#mobile-menu', function() {
    const $links = $('#mobile-menu a.item');

    $('#mobile-menu').on('click', 'a.item', function() {
      const $link = $(this);
      const $linkIcon = $link.children().not('.menu-text');

      if (bpHelper.isMatching('md', true)) {
        if ($link.hasClass('more-items')) {
          const $popOver = $('.more-menu-popover');

          $popOver.off('click', 'a.item');
          $popOver.on('click', 'a.item', function() {
            $links.removeClass('active');
            $link.addClass('active');
            showLoader($link, $linkIcon, loaderHtml, $links);
          });
        } else if (!$link.hasClass('ignore-click-status')) {
          showLoader($link, $linkIcon, loaderHtml, $links);
        }
      }

      $(document).on('turbo:before-fetch-response', () => {
        $link.find('.icon_cloud').remove();
        $linkIcon.show();
        $links.css('pointer-events', 'auto');
        if (!$link.hasClass('ignore-click-status')) {
          $link.css('pointer-events', 'none');
        }
      });
    });
  });
}

global.selectBottomMenu = selectBottomMenu;
global.menuLoader = menuLoader;
