import 'arrive/src/arrive';
import { Turbo } from '@hotwired/turbo-rails';

const goToObfLink = (obfElement) => {
  const $obfElement = $(obfElement);
  $obfElement.on('click', function(e) {
    const $targetElement = $(e.target);
    const urlValue = $targetElement.attr('data-url');
    const targetValue = $targetElement.attr('target');
    const turboNavigation = $targetElement.data('turbo');

    if (urlValue === undefined) {
      return;
    }

    if (targetValue !== undefined) {
      window.open(urlValue, targetValue);
    } else if (turboNavigation !== 'false') {
      Turbo.visit(urlValue);
    } else {
      window.location.href = urlValue;
    }
  });
};

document.arrive('.obf-link', goToObfLink);
