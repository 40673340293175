/**
 * Show / hide the checked / unchecked selectors that are on the input's data attribute
 * when the input is checked or not
 * This component can be used for checkbox and radio
 * @param input
 */
const showHideAssociatedSelector = function(input) {
  const metaData = input.dataset;
  const groupIdSelectorUndefined = metaData.groupIdSelectorUndefinedDisplay;
  if (groupIdSelectorUndefined) {
    const has_any_checked = $(`input[data-group-id-selector-undefined-display="${groupIdSelectorUndefined}"]:checked`).length > 0;
    $(groupIdSelectorUndefined).toggle(!has_any_checked);
  }

  if (input.type === 'radio') {
    updateRadio(input);
  } else {
    updateCheckbox(input);
  }
};

function updateRadio(element) {
  const $form = $(element).closest('form');
  const $items = $form.find('input[name="' + element.name + '"]');
  let anyChecked = false;

  $items.each(function() {
    if ($(this).is(':checked')) {
      anyChecked = true;
      return false;
    }
  });

  $items.each(function(k, item) {
    // remote control for dom display
    // updateItemStatus(item, anyChecked);

    const metaData = $(item).data();
    const uncheckedSelector = metaData.uncheckedSelectorDisplay;
    const checkedSelector = metaData.checkedSelectorDisplay;
    const undefinedSelector = metaData.undefinedSelectorDisplay;
    const definedSelector = metaData.definedSelectorDisplay;
    if (undefinedSelector && anyChecked) $(undefinedSelector).hide();
    if (definedSelector && anyChecked) $(definedSelector).show();
    if (uncheckedSelector) $(uncheckedSelector).hide();
    if (checkedSelector) $(checkedSelector).hide();
    if (undefinedSelector && !anyChecked) $(undefinedSelector).show();
    if (definedSelector && !anyChecked) $(definedSelector).hide();
  });
  // need to run it AFTER the first pass, otherwise selector checked can collide with other checked value
  if (anyChecked) {
    // if any radio are checked we need show the checked selector or the unchecked selector if not checked
    $items.each(function(k, item) {
      const metaData = $(item).data();
      const checkedSelector = metaData.checkedSelectorDisplay;
      const uncheckedSelector = metaData.uncheckedSelectorDisplay;
      if (item.checked) {
        if (checkedSelector) $(checkedSelector).show();
      } else {
        if (uncheckedSelector) $(uncheckedSelector).show();
      }
    });
  }
}

const updateCheckbox = function(input) {
  const metaData = input.dataset;
  const uncheckedSelector = metaData.uncheckedSelectorDisplay;
  const checkedSelector = metaData.checkedSelectorDisplay;

  if (input.checked) {
    if (uncheckedSelector) $(uncheckedSelector).hide();
    if (checkedSelector) $(checkedSelector).show();
  } else {
    if (uncheckedSelector) $(uncheckedSelector).show();
    if (checkedSelector) $(checkedSelector).hide();
  }
};

const otherRadioButtonsWithName = function(name) {
  // Find all radio buttons with the specified name
  const radioButtons = document.querySelectorAll(`input[type="radio"][name="${name}"]`);

  // Filter the radio buttons to find the unchecked ones
  return Array.from(radioButtons).filter(radio => !radio.checked);
};

export { showHideAssociatedSelector, otherRadioButtonsWithName };
