$(document).on('turbo:load', function() {
  // check if we are currently on the review page on nounou-top / aide-au-top
  // check if there is currently a hash present in the url
  if (document.querySelector('.review-filter-container') && window.location.hash) {
    updateReviewContent($(`.review-filter-container .review-filter.${window.location.hash.replace('#', '')}`).data('renderPath'));
  }
});

$(document).arrive('.review-filter', function() {
  $(this).on('click', function() {
    const $this = $(this);
    const renderPath = $this.data('renderPath');
    $('.review-filter-container').removeClass('checked').addClass('indeterminate');
    $this.parent().removeClass('indeterminate').addClass('checked');
    updateReviewContent(renderPath);
  });
});

const updateReviewContent = (path) => {
  $.ajax({
    url: path,
    method: 'GET',
    headers: {
      Accept: 'text/vnd.turbo-stream.html',
      'X-CSRF-TOKEN': document.querySelector('meta[name=csrf-token]').content,
    },
    success: function(response) {
      Turbo.renderStreamMessage(response);
    },
  });
};
