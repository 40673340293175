import { Controller } from '@hotwired/stimulus';

const categoryActiveClass = 'category-search__category-list-item--selected';

export default class extends Controller {
  static targets = [
    'background',
    'category',
  ];

  static values = {
    category: String,
  };

  updateCategory({ detail: { category } }) {
    if (this.categoryValue === category) return;
    this.categoryValue = category;
    this.removeCategoryActiveClass();
    this.addCategoryActiveClass(category);
    this.transitionBackground(category);
  }

  transitionCategory(event) {
    const element = event.target;
    // return if is the active category
    if (element.classList.contains(categoryActiveClass)) return false;
    this.updateCategory({ detail: { category: event.params.category } });
  }

  removeCategoryActiveClass() {
    this.categoryTargets.forEach(target => {
      target.classList.remove(categoryActiveClass);
    });
  }

  addCategoryActiveClass(category) {
    $(`.category-search__category-list-item-${category}`).addClass(categoryActiveClass);
  }

  transitionBackground(category) {
    if (!this.hasBackgroundTarget) return;
    $(this.backgroundTargets).fadeTo(300, 0, function() {
      const $element = $(this);
      const backgroundUrl = $element.data(`${category}Background`);
      $element.css('background-image', `url(${backgroundUrl})`);
    }).fadeTo(300, 1);
  }
}
