import 'arrive/src/arrive';
import bsModal from 'bootstrap/js/dist/modal';
import { bpHelper } from './utils';
import Offcanvas from 'bootstrap/js/dist/offcanvas';

document.arrive('.share-button', function(element) {
  element.addEventListener('click', () => {
    let id = 1;
    if ('id' in element.dataset) {
      id = element.dataset.id;
    }

    const modalElement = document.getElementById(`share-this-modal-${id}`);

    if (bpHelper.isMatching('md', true)) {
      const $offCanvasElement = $(`.share-offcanvas-${id}:visible`);
      const $parent = $offCanvasElement.parents('.modal.show').first();
      const offCanvas = Offcanvas.getOrCreateInstance($offCanvasElement[0]);

      // Custom z-index if we are in a modal else it will be shown under
      if ($parent.length > 0) {
        $(offCanvas._element).css('z-index', $parent.css('z-index') * 1 + 2);
        $(offCanvas._backdrop._element).css('z-index', $parent.css('z-index') * 1 + 1);
      }
      $('.offcanvas').offcanvas('hide');
      offCanvas.show();
      // we check if sharethis is present as it may disabled by user on cookie consent
      if (window.__sharethis__) {
        $offCanvasElement[0].addEventListener('shown.bs.offcanvas', event => {
          window.__sharethis__.initialize();
        }, { once: true });
      }
    } else {
      const modal = bsModal.getOrCreateInstance(modalElement);
      modal.show();
      // we check if sharethis is present as it may disabled by user on cookie consent
      if (window.__sharethis__) {
        modalElement.addEventListener('shown.bs.modal', event => {
          window.__sharethis__.initialize();
        }, { once: true });
      }
    }
  });
});
