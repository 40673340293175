const setUtmCookies = function() {
  // Fonction pour lire les paramètres de l'URL
  const allowedParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_date'];

  const getQueryParams = function() {
    const params = {};
    const queryString = window.location.search.substring(1);
    const regex = /([^&=]+)=([^&]*)/g;
    let m;
    while ((m = regex.exec(queryString)) !== null) {
      const key = decodeURIComponent(m[1]);
      const value = decodeURIComponent(m[2]);
      if (allowedParams.includes(key)) {
        params[key] = value;
      }
    }

    return params;
  };

  // Fonction pour définir un cookie
  const setCookie = function(name, value, days) {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  };

  // Récupérer les paramètres de l'URL
  const params = getQueryParams();

  // Définir les cookies pour chaque paramètre utm_*
  const hasOwnProperty = Object.prototype.hasOwnProperty.bind(params);
  for (const key in params) {
    if (hasOwnProperty(key) && allowedParams.includes(key)) {
      setCookie(key, params[key], 365); // Définir un cookie pour 1 an
    }
  }
};

document.addEventListener('turbo:load', setUtmCookies);
