document.addEventListener('turbo:load', function() {
  window.addEventListener('beforeinstallprompt', async(e) => {
    e.preventDefault();
    window.deferredPrompt = e;
  });

  // show the install app link on the web application
  if (window.matchMedia('(display-mode: standalone)').matches) {
    $('.pwa-app-install-link').remove();
  }

  setTimeout(function() {
    if (typeof window.deferredPrompt !== 'undefined') {
      const $installButton = $('.install-app__with-prompt-btn');
      $installButton.addClass('install-app__with-prompt-btn--active');
      $installButton.click(function() {
        window.deferredPrompt.prompt();
      });
    } else {
      $('.install-app__without-prompt').addClass('install-app__without-prompt--active');
    }
  }, 500);
});
