const changeMinimalLengthStyle = () => {
  const $minimumText = $('#minimal-length-text');
  const $missingCharacters = $('#missing-characters');
  const $manyCharacters = $('#many-characters');
  const $oneCaracter = $('#one-character');
  const $minimalLengthSuccess = $('#minimal-length-success');
  const $adDescription = $('#ad_description');
  const $counter = $('#characterCounter');
  const $errorIcon = $('#length-error-icon');
  const $successIcon = $('#length-success-icon');
  let remainingCharacters = 20 - $adDescription.val().replace(/\s+/g, '').length;

  $counter.html(remainingCharacters);

  function switchMessageStyle(remainingCharacters) {
    $counter.html(remainingCharacters);
    if ($adDescription.val().replace(/\s+/g, '').length < 20) {
      $minimumText.css('color', '#e84d67');
      $minimalLengthSuccess.hide();
      if (remainingCharacters === 1) {
        $manyCharacters.hide();
        $oneCaracter.show();
      } else {
        $oneCaracter.hide();
        $manyCharacters.show();
      }
      $missingCharacters.show();
      $successIcon.hide();
      $errorIcon.show();
    } else {
      $minimumText.css('color', '#86d15b');
      $missingCharacters.hide();
      $manyCharacters.hide();
      $oneCaracter.hide();
      $minimalLengthSuccess.show();
      $errorIcon.hide();
      $successIcon.show();
    }
  }

  switchMessageStyle(remainingCharacters);

  $adDescription.on('input', () => {
    remainingCharacters = 20 - $adDescription.val().replace(/\s+/g, '').length;
    switchMessageStyle(remainingCharacters);
  });
};

global.changeMinimalLengthStyle = changeMinimalLengthStyle;
