import { scrollToItem } from '../../design_system/inputs/scroll_on_focus';

$(document).on('turbo:load', function() {
  const $body = $('body.child-payslip-show');

  $body.on('click', '.toggle-parameters', function() {
    const $title = $('#payslip__parameters-title');
    // open accordion if it closed
    if (!$title.hasClass('accordion-item--active')) {
      $title.find('button').click();
    }
    // scroll to the from
    scrollToItem($title.first());
  });
});
