import dayjs from 'dayjs';
import dayjsDuration from 'dayjs/plugin/duration';
import dayjsMinMax from 'dayjs/plugin/minMax';
import dayjsCustomParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(dayjsDuration);
dayjs.extend(dayjsMinMax);
dayjs.extend(dayjsCustomParseFormat);

$.fn.attendanceManagement = function(formType) {
  const res = this;
  this.formType = formType;
  $('.attendance-form__edit-button').on('change', function() {
    const checked = $(this).prop('checked');
    $('.attendance-form__has-changes').val(!checked);
  }).change();

  this.each(function() {
    const form = $(this);

    const attendanceDay = new AttendanceDay(form, formType);
    attendanceDay.updateTimes();
    form.on('change', '.attendance-form__time-select select', function(e) {
      e.preventDefault();
      attendanceDay.adjustSelectTimes($(this));
      attendanceDay.updateTimesheet($(this).parents('.attendance-form__time-select'));
      refreshHeight();
      return true;
    }).change();
    form.on('click', '.delete-attendance-timesheet', function(e) {
      e.preventDefault();
      const attendanceTimesheet = $(this).parents('.attendance-form__time-select');
      const $nestedParent = attendanceTimesheet.parents('.attendance-form__timesheet-wrapper');
      attendanceTimesheet.find('.attendance-absence').prop('checked', true);
      attendanceDay.updateTimesheet(attendanceTimesheet);
      attendanceTimesheet.hide();
      $nestedParent.find('.attendance-form__responsible-duration').hide();
      $nestedParent.find('.attendance-form__timesheet-header').hide();
      $nestedParent.find('.attendance-form__night-intervention').hide();

      refreshDeleteButton();
      return true;
    });
    form.on('fields_added.nested_form_fields', function(event, param) {
      event.preventDefault();
      $(event.target).find('.attendance-form__time-select').attr('data-timesheet-id', token());
      attendanceDay.addTimesheet($(event.target).find('.attendance-form__time-select'));
      return true;
    });

    form.find('.attendance-form__absence-deducted-type').on('change', function() {
      form.find('.absence_deducted_type_message>div').hide();
      form.find('.absence_deducted_type_message .' + $(this).val() + '_message').show();
      // hack on ged to have all the absences reasons to be the same
      $('.attendance-form__absence-reasons select').val($(this).val());
      $('.absence_deducted_type_message .' + $(this).val() + '_message').show();
    }).change();

    form.on('change', '.attendance-form__charge-everything input:visible', function(e) {
      e.preventDefault();
      const $selector = $(this)
        .parents('.additional-time-charged')
        .find('.attendance-form__charged-additional-duration-time');
      updateSelectValue($(this).val(), $selector, attendanceDay.additional_time);
      return true;
    });

    form.on('change', '.attendance-form__deduct-all-absence input:visible', function(e) {
      e.preventDefault();
      const $selector = $(this).parents('.absence-time-deduction').find('.attendance-form__absence-deducted-time');
      updateSelectValue($(this).val(), $selector, attendanceDay.absence_time);
      return true;
    });
  });

  return res;
};

function refreshHeight() {
  $('input[type=\'submit\']').toggle();
  setTimeout(function() {
    $('input[type=\'submit\']').toggle();
  }, 100);
}

const AttendanceDay = function(form, type) {
  this.formType = type;

  this.selectors = function() {
    if (type === 'assmat') {
      return {
        timeRange: '.attendance-time-range',
        additionalTime: '.additional-time-wrapper',
        absenceTime: '.absence-time-wrapper',
      };
    }
    // GED
    return {
      timeRange: '.attendance-form__time-range-' + this.formType,
      additionalTime: '.attendance-form__additional-time-' + this.formType,
      absenceTime: '.attendance-form__absence-time-' + this.formType,
    };
  };

  this.timeranges = _.map($(this.selectors().timeRange), function(element) {
    const $element = $(element);
    if ($element.find('.starts-at').text() === '00h00' && $element.find('.ends-at').text() === '00h00') {
      $element.find('.ends-at').text('24h00');
    }
    return new Timerange($element);
  });

  this.timesheets = _.map($(form).find('.attendance-form__time-select'), function(element) {
    return new Timesheet($(element));
  });

  _.each(this.timesheets, function(timesheet) {
    // remise à 24h00 si le compteur, car le compteur revient automatiquement à 00h00
    if (timesheet.ends_at.hour() === 0 && timesheet.ends_at.minute() === 0) {
      timesheet.ends_at = timesheet.ends_at.add(24, 'hours');
      $(timesheet.element.find('.ends-at').find('select')[0]).val(24);
    }
  });

  this.additional_time = 0;
  this.absence_time = 0;

  this.valid_timesheets = function() {
    const that = this;

    return _.filter(that.timesheets, function(timesheet) {
      return !timesheet.absence;
    });
  };

  this.updateTimes = function() {
    const that = this;
    that.additional_time = 0;
    that.absence_time = 0;

    const intersectedTimes = [];

    _.each(that.timeranges, function(timerange) {
      _.each(that.valid_timesheets(), function(timesheet) {
        const intersectionTime = intersectionRange(timesheet, timerange);
        if (intersectionTime.duration() > 0) {
          intersectedTimes.push(intersectionTime);
        }
      });
    });

    const intersectedTimesDuration = getTimesDuration(intersectedTimes);
    const theoreticalDuration = getTimesDuration(that.timeranges);

    that.absence_time = theoreticalDuration - intersectedTimesDuration;
    that.additional_time = getTimesDuration(that.valid_timesheets()) - intersectedTimesDuration;
    updateFormChanges(that.additional_time, '.additional-time', that.selectors().additionalTime, that.formType);
    updateFormChanges(that.absence_time, '.absence-time', that.selectors().absenceTime, that.formType);
    $(that.selectors().absenceTime + ' .full-day-deduction-alert')
      .toggle(that.additional_time === 0 && that.absence_time === theoreticalDuration);

    $('.child_absent_and_worked_schedule').toggle(that.valid_timesheets().length === 0);
  };

  this.updateTimesheet = function(element) {
    const that = this;
    const newTimesheet = new Timesheet(element);
    const newTimesheetIndex = _.findIndex(that.timesheets, function(timesheet) {
      return timesheet.id === newTimesheet.id;
    });
    that.timesheets[newTimesheetIndex] = newTimesheet;
    that.updateTimes();
  };

  this.addTimesheet = function(element) {
    const that = this;
    that.timesheets.push(new Timesheet(element));
    that.updateTimes();
  };

  this.removeTimesheet = function(element) {
    const that = this;
    const newTimesheet = new Timesheet(element);
    const timesheetIndex = _.findIndex(that.timesheets, function(timesheet) {
      return timesheet.id === newTimesheet.id;
    });

    that.timesheets.splice(timesheetIndex, 1);
    that.updateTimes();
  };

  this.adjustSelectTimes = function(element) {
    const $timesheet = element.parents('.attendance-form__time-select');
    const $endsAtSelect = $timesheet.find('.ends-at select');
    const $endsAtHours = $endsAtSelect.eq(0);
    const $endsAtMinutes = $endsAtSelect.eq(1);

    // Pour empêcher de dépasser minuit (ex 24h15)
    if ($endsAtHours.val() === '24') {
      $endsAtMinutes.val('00');
    }
    // Pour empêcher de déclarer minuit par 00h00 et forcer 24h00
    if ($endsAtHours.val() === '00' && $endsAtMinutes.val() === '00') {
      $endsAtHours.val('24');
    }

    const timesheet = new Timesheet($timesheet);
    if (timesheet.starts_at > timesheet.ends_at) {
      if (element.parent().hasClass('starts-at')) {
        timesheet.updateTime('ends-at', timesheet.starts_at);
      } else {
        timesheet.updateTime('starts-at', timesheet.ends_at);
      }
    }
  };
};

const Timesheet = function(element) {
  this.element = element;
  this.id = element.data('timesheet-id');
  this.absence = element.find('.attendance-absence').prop('checked');
  this.starts_at = toTime(selectsToString(element.find('.starts-at')));
  this.ends_at = toTime(selectsToString(element.find('.ends-at')));
  this.duration = function() {
    if (this.absence) {
      return 0;
    } else {
      return minutesOffset(this.ends_at, this.starts_at);
    }
  };
  this.updateTime = function(method, time) {
    $(this.element.find('.' + method).find('select')[0]).val(time.format('HH'));
    $(this.element.find('.' + method).find('select')[1]).val(time.format('mm'));
  };
};

const Timerange = function(element) {
  this.id = element.data('time-range-id');
  this.starts_at = toTime(element.find('.starts-at').html());
  this.ends_at = toTime(element.find('.ends-at').html());

  if (this.ends_at.startOf('day').isSame(this.ends_at) && this.ends_at.day() === this.starts_at.day()) {
    this.ends_at = this.ends_at.add(1, 'days');
  }

  this.duration = function() {
    return minutesOffset(this.ends_at, this.starts_at);
  };
};

const Time = function(startsAt, endsAt) {
  this.starts_at = startsAt;
  this.ends_at = endsAt;
  this.duration = function() {
    return minutesOffset(this.ends_at, this.starts_at);
  };
};

function selectsToString(element) {
  return _.map(element.find('select'), function(el) {
    return $(el).val();
  }).join(':');
}

function minutesOffset(last, first) {
  if (last.isBefore(first) && last.hour() === 0) {
    last = last.add(1, 'days');
  }
  return parseInt(last.diff(first, 'minutes'));
}

function toTime(element) {
  return dayjs(element, 'HH:mm');
}

function updateFormChanges(value, className, wrapper, formType) {
  if (value > 0) {
    $(wrapper + ' ' + className + ' span.value').html(humanizeDuration(value));
    $(wrapper + ' ' + className + '-input').val(value);
    if (formType !== 'night') {
      $(wrapper).show();
    }
  } else {
    $(wrapper + ' ' + className + '-input').val(0);
    $(wrapper).hide();
  }
}

function updateSelectValue(value, selector, method) {
  $(selector).toggle(value !== 'true');
}

function humanizeDuration(duration) {
  let result = '';
  const dayjsDuration = dayjs.duration(duration, 'minutes');
  if (dayjsDuration.asHours() >= 1) {
    result += Math.floor(dayjsDuration.asHours()) + 'h';
  }
  if (dayjsDuration.minutes() > 0) {
    result += ((dayjsDuration.asHours() >= 1) ? ' ' : '') + dayjsDuration.minutes() + 'min';
  }
  if (result === '') {
    return 'Aucune';
  } else {
    return result;
  }
}

function intersectionRange(a, b) {
  return new Time(dayjs.max(dayjs.min(a.starts_at, b.ends_at), b.starts_at),
    dayjs.min(dayjs.max(b.starts_at, a.ends_at), b.ends_at));
}

function token() {
  return Math.random().toString(36).substr(2) + Math.random().toString(36).substr(2);
}

function getTimesDuration(elements) {
  return _.reduce(elements, function(memo, timerange) {
    return memo + timerange.duration();
  }, 0);
}

function refreshDeleteButton() {
  if ($('.attendance-form__edit-button').prop('checked')) return;
  if ($('.attendance-form__time-select:visible').length > 0) return;

  $('.attendance-form__meal .radio_buttons .radio_buttons_menu_wrapper').each(function() {
    $(this).find('.radio_button').first().click();
  });
}

$(document).ready(function() {
  const attendanceLunchConfirmation = new ConfirmLunch(
    {
      parent: '.nested-contract-day-timesheets',
      start: '.starts-at',
      end: '.ends-at',
    }
  );

  $(document).on('change', '.nested-contract-day-timesheets .starts-at select', function() {
    attendanceLunchConfirmation.visible = false;
    attendanceLunchConfirmation.warningToggle();
  });
  $(document).on('change', '.nested-contract-day-timesheets .ends-at select', function() {
    attendanceLunchConfirmation.visible = false;
    attendanceLunchConfirmation.warningToggle();
  });
  $(document).on('change', '.attendance-form__meal-lunch', function() {
    attendanceLunchConfirmation.visible = false;
    attendanceLunchConfirmation.warningToggle();
  });
  $(document).on('click', '.delete-attendance-timesheet', function() {
    attendanceLunchConfirmation.visible = true;
    attendanceLunchConfirmation.warningToggle();
  });
  $(document).on('change', '.attendance-form__edit-button', function() {
    attendanceLunchConfirmation.visible = false;
    attendanceLunchConfirmation.warningToggle();
  });
});

const ConfirmLunch = function(timeScheduleWrappers) {
  this.timeScheduleWrappers = timeScheduleWrappers;

  // defaults
  this.warningMessage = '.mandatory-lunch-warning';
  this.mandatoryStartTime = '.mandatory-start-time';
  this.mandatoryEndTime = '.mandatory-end-time';
  this.lunchCheckBox = '.attendance-form__meal-lunch:checked';

  // particulars of one interface with more specificities
  this.noChangeCheckbox = '.attendance-form__edit-button';
  this.visible = false;

  this.warningToggle = function() {
    $(this.warningMessage).toggle(this.needWarning());
  };

  this.needWarning = function() {
    const lunchSelected = this.isLunchSelected();
    if (!lunchSelected) {
      return this.isTimeMandatoryLunchTime();
    }
    return false;
  };

  this.isLunchSelected = function() {
    return $(this.lunchCheckBox).val() !== 'on';
  };

  this.isTimeMandatoryLunchTime = function() {
    const mandatoryStartTime = $(this.mandatoryStartTime).val();
    const mandatoryEndTime = $(this.mandatoryEndTime).val();
    let mandatoryLunch = false;
    const startSelectSelector = this.timeScheduleWrappers.start + ' select';
    const endSelectSelector = this.timeScheduleWrappers.end + ' select';
    let noChange = true;
    // hardcoded check on a button that is only present on one form and not the other one
    if ($(this.noChangeCheckbox).length > 0) {
      noChange = $(this.noChangeCheckbox).prop('checked');
    }
    if ((!noChange || this.visible) &&
            $(this.timeScheduleWrappers.parent + ' ' + startSelectSelector + ':visible').length < 1) {
      return false;
    }
    $(this.timeScheduleWrappers.parent).each(function() {
      const starts = $(this).find(startSelectSelector).eq(0).val() + ' ' +
                $(this).find(startSelectSelector).eq(1).val();
      const ends = $(this).find(endSelectSelector).eq(0).val() + ' ' + $(this).find(endSelectSelector).eq(1).val();
      mandatoryLunch = starts <= mandatoryStartTime && ends >= mandatoryEndTime;
      if (mandatoryLunch) {
        return false;
      }
    });
    return mandatoryLunch;
  };
};

const GedAttendanceManagement = {
  nightScheduleHoursOptionsToRemove: ['09', '10', '11', '12', '13', '14', '15', '16', '17'],
  nightInterventionParentClass: {
    original: 'attendance-form__night-intervention-original',
    clone: 'attendance-form__night-intervention-copy',
  },
  bindEvents: function() {
    $('.attendance-form__time-select--ged .starts-at, .attendance-form__time-select--ged .ends-at').change(function() {
      GedAttendanceManagement.hideTimesheetError($(this).parents('attendance-form__timesheet-wrapper').first());
    });

    $(document).on('change', '.attendance-form__responsible-duration select', function() {
      GedAttendanceManagement.hideResponsibleDurationError(this);
    });

    $('.attendance-form__night-schedule').on('fields_added.nested_form_fields', function() {
      GedAttendanceManagement.removeNightScheduleTimeRanges();
    });

    this.removeNightScheduleTimeRanges();
    this.cloneNightIntervention();

    $(document).on('change', '.attendance-form__night-intervention-select', function() {
      GedAttendanceManagement.manageNightIntervention(this);
    });

    $(document).on('change', '.attendance-form__interventions-duration select.hour', function() {
      GedAttendanceManagement.manageNightInterventionDuration(this, '.attendance-form__interventions-duration select.hour');
    });

    $(document).on('change', '.attendance-form__interventions-duration select.minute', function() {
      GedAttendanceManagement.manageNightInterventionDuration(this, '.attendance-form__interventions-duration select.minute');
    });
  },
  removeNightScheduleTimeRanges: function() {
    this.nightScheduleHoursOptionsToRemove.forEach(function(value) {
      $('.attendance-form__night-schedule .attendance-form__time-select .starts-at').each(function() {
        $(this).find('select:first option[value=\'' + value + '\']').remove();
      });

      $('.attendance-form__night-schedule .attendance-form__time-select .ends-at').each(function() {
        $(this).find('select:first option[value=\'' + value + '\']').remove();
      });
    });
  },
  hideTimesheetError: function(element) {
    $(element).find('.attendance-form__timesheet-error').hide();
    $(element).find('.is-invalid').removeClass('.is-invalid');
  },
  manageNightIntervention: function(element) {
    const $element = $(element);
    const $parentContainer = $element.parents('.attendance-form__night-intervention');
    const timeRangeId = $parentContainer.data('time-range-id');
    const elementValue = $element.val();
    const showNightInterventionDuration = elementValue === '3';
    const $otherElementParent = this.nightInterventionOtherElementParent($element, timeRangeId);

    // toggle duration
    $parentContainer.find('.attendance-form__night-intervention-duration').toggle(showNightInterventionDuration);
    $otherElementParent.find('.attendance-form__night-intervention-select').val(elementValue);

    // Resets all the values if the number of interventions are less than 4
    if (!showNightInterventionDuration) {
      $parentContainer.find('select.hour').val('0');
      $parentContainer.find('select.minute').val('0');
      $otherElementParent.find('select.hour').val('0');
      $otherElementParent.find('select.minute').val('0');
    }
  },
  manageNightInterventionDuration: function(element, className) {
    const $element = $(element);
    const value = $element.val();
    const $parentContainer = $element.parents('.attendance-form__night-intervention');
    const timeRangeId = $parentContainer.data('time-range-id');
    const $otherParent = this.nightInterventionOtherElementParent($element, timeRangeId);

    $otherParent.find(className).val(value);
    // hide errors
    $parentContainer.find('.attendance-form__intervention-duration-error').hide();
    $otherParent.find('.attendance-form__intervention-duration-error').hide();
  },
  nightInterventionOtherElementParent: function(element, timeRangeId) {
    const containerParentClass = element.parents('.attendance-form__night-intervention').parent().prop('className');
    const otherParent = containerParentClass === this.nightInterventionParentClass.original
      ? this.nightInterventionParentClass.clone
      : this.nightInterventionParentClass.original;
    return $('.' + otherParent + ' .attendance-form__night-intervention[data-time-range-id=\'' + timeRangeId + '\']');
  },
  cloneNightIntervention: function() {
    const duplicated = $('.attendance-form__night-intervention-copy')
      .find('.attendance-form__night-intervention').length > 0;
    if (!duplicated) {
      $('.attendance-form__night-intervention').each(function() {
        const $this = $(this);
        const timeRangeId = $(this).data('time-range-id');
        $this.clone().appendTo('.attendance-form__night-intervention-copy[data-time-range-id=\'' + timeRangeId + '\']');
      });
    }
  },
  hideResponsibleDurationError: function(element) {
    $(element)
      .parents('.attendance-form__simple-timesheet')
      .find('.attendance-form__responsible-duration-error')
      .hide();
  },
};

// script was in the views/children/events/edit.html.slim
$(document).on('turbo:load', function() {
  const $body = $('body');
  const changesToggleElement = '.changes_toggle input.toggle';
  const lunchPropagationElement = '.lunches_propagation';
  const lunchesTogglesElement = '.attendance-form__meal .daily_lunch_toggle';
  const applyToggleElement = '.apply_toggle input.toggle';
  const propagateToggleElement = '.propagate_toggle input.toggle';

  $body.on('change', changesToggleElement, function() {
    if ($(this).prop('checked')) return;

    $(lunchPropagationElement).hide();
    $(`${lunchPropagationElement} .checkbox`).prop('checked', false);
  });

  $body.on('change', lunchesTogglesElement, function() {
    if (!$(changesToggleElement).prop('checked')) return false;
    let visible = false;
    $(lunchesTogglesElement).filter(':checked').each(function(k, v) {
      const toggle = $(v);
      if (hasMealDiff(toggle, $('.attendance-form--ged').length > 0)) {
        visible = true;
      }
    });
    $(lunchPropagationElement).toggle(visible);
  });

  function hasMealDiff(toggle, careTypeGed) {
    let defaultValue = toggle.data('default');
    const value = careTypeGed ? toggle.prop('checked') : toggle.val();
    if (value === 'on' && defaultValue === undefined) return false;
    if (!careTypeGed && !defaultValue) defaultValue = 'on';
    return value !== defaultValue;
  }

  $body.on('change', applyToggleElement, function() {
    $('.propagate_checkbox').toggle($(applyToggleElement).prop('checked'));
  });

  $body.on('change', propagateToggleElement, function() {
    $('.propagate_details').toggle($(propagateToggleElement).prop('checked'));
  });
});

export { GedAttendanceManagement };
