function updateInput(element) {
  const $form = $(element).closest('form');
  const $items = $form.find('input[name="' + element.name + '"]');
  let anyChecked = false;

  $items.each(function() {
    if ($(this).is(':checked')) {
      anyChecked = true;
      return false;
    }
  });

  $items.each(function(k, item) {
    // remote control for dom display
    updateItemStatus(item, anyChecked);

    const metaData = $(item).data();
    const uncheckedSelector = metaData.uncheckedSelectorDisplay;
    const checkedSelector = metaData.checkedSelectorDisplay;
    const undefinedSelector = metaData.undefinedSelectorDisplay;
    const definedSelector = metaData.definedSelectorDisplay;
    if (undefinedSelector && anyChecked) $(undefinedSelector).hide();
    if (definedSelector && anyChecked) $(definedSelector).show();
    if (uncheckedSelector) $(uncheckedSelector).hide();
    if (checkedSelector) $(checkedSelector).hide();
    if (undefinedSelector && !anyChecked) $(undefinedSelector).show();
    if (definedSelector && !anyChecked) $(definedSelector).hide();
  });
  // need to run it AFTER the first pass, otherwise selector checked can collide with other checked value
  if (anyChecked) {
    // if any radio are checked we need show the checked selector or the unchecked selector if not checked
    $items.each(function(k, item) {
      const metaData = $(item).data();
      const checkedSelector = metaData.checkedSelectorDisplay;
      const uncheckedSelector = metaData.uncheckedSelectorDisplay;
      if (item.checked) {
        if (checkedSelector) $(checkedSelector).show();
      } else {
        if (uncheckedSelector) $(uncheckedSelector).show();
      }
    });
  }
}

function setupRadios(item) {
  const $item = $(item);
  const $parent = $item.parent();

  updateInput(item);

  $parent.click(function(e) {
    /**
     * The click on a label triggers, naturally, a click on the input with which is it associated, so we need to
     * stop that click as we are already adding a click on the parent of the input.
     *
     * Why you ask?
     * We need to add a click on the parent, as the radio mat not be dependant on the click of the label only as a radio
     * can take many form and shapes
     *
     * Stopping the click on the label
     * To do so we check if the target of the click is the label or a child of the label and then we need to
     * check if the label and the input that sits inside the same parent are really associated by an id.
     *
     * If they are not associated (names with specific characters may not be tolerated so creating different
     * ids or input with no value may generate different ids) we have to let the click on the label through.
     *
     */
    const $label = $parent.find('label');
    const labelForValue = $label.attr('for');
    const inputId = $item.attr('id');

    if ((e.target.tagName === 'LABEL' || $(e.target).closest('label').length > 0) && labelForValue === inputId) {
      return;
    } else if (!item.checked) {
      // We do not want to enter this condition if the element is already checked, because if it is already checked,
      // it means that the click was performed on the label and naturally the element was checked and the change event
      // was triggered
      item.checked = true;
      /**
       * As we check the element manually we send a change event on the element so that it can be caught
       * by other scripts that wait for a change event on the element.
       */
      item.dispatchEvent(new Event('change'));
    }

    updateInput(item);

    if (item.form.dataset.autosubmit) {
      item.form.requestSubmit();
    }
  });
}

function updateItemStatus(item, anyChecked) {
  const $parent = $(item).parent();

  if(item.disabled) {
    $parent.addClass('disabled');
  }
  $parent[item.checked ? 'addClass' : 'removeClass']('checked');
  $parent[anyChecked ? 'removeClass' : 'addClass']('indeterminate');
  $parent[anyChecked && !item.checked ? 'addClass' : 'removeClass']('unchecked');
}

document.arrive("form input[type='radio']:not(.radio_illustration)", function(item) {
  setTimeout(function() { setupRadios(item); });
});
