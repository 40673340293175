document.addEventListener('turbo:load', function() {
  const $body = $('body');

  $body.on('click', '.flyer-link', function(e) {
    const element = e.target;

    if (displaySelectError()) {
      e.preventDefault();
      return;
    } else {
      $('.flyer-select-error').addClass('d-none');
    }
    const flyerLinks = flyerLinksValue();

    $(e.target).attr('href', flyerLinks[element.dataset.jsonAtt]);
  });
});

/**
 * We need to convert a string within a string to json
 * @returns {json}
 */
const flyerLinksValue = () => {
  const flyerLinksValue = $('.flyer-action-links').val();
  return JSON.parse(flyerLinksValue.substring(1, flyerLinksValue.length - 1));
};

const displaySelectError = () => {
  const flyerLinksValue = $('.flyer-action-links').val();
  if (flyerLinksValue.trim().length === 0) {
    $('.flyer-select-error').removeClass('d-none');
    return true;
  }

  return false;
};
