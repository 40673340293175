import { Controller } from '@hotwired/stimulus';
import Popover from 'bootstrap/js/dist/popover';

export default class extends Controller {
  static targets = [
    'filterForm',
    'input',
    'datePicker',
    'resetForm',
  ];

  connect() {
    // small hack to remove the overflow added to the body by an opened modal and there was a turbo reload
    document.body.style.removeProperty('overflow');

    this.interceptAndAddSubmitEvent();
    document.arrive('#search-filters-modal-body', () => {
      this.interceptAndAddSubmitEvent();
    });

    this.addEventListenerToDistance();

    // initialize the distance value
    this.distanceValue = null;
  }

  addEventListenerToDistance() {
    /**
     * As the distance filter will be in a popup / reloaded through turbo stream we need to watch for
     * when it arrives in the DOM and then listen to the event change on it.
     *
     * WARNING: Do not use jquery for the event change, as the event change may be dispatched through vanilla js
     * (through radio-button.js) and not be catchable by jquery
     */
    document.arrive('.search-result__distance-filter', (element) => {
      element.addEventListener('change', () => {
        this.changeDistance(element);
      });
    });

    /**
     * We also need to add event listener directly as we connect as the distance filter are already loaded
     * on the modal as opposed to the ones that are loaded later in the popover
     */
    document.querySelectorAll('.search-result__distance-filter').forEach(element => {
      element.addEventListener('change', () => {
        this.changeDistance(element);
      });
    });
  }

  filterLanguage() {
    setTimeout(() => this.submitForm(), 100);
  }

  filterResult() {
    this.submitForm();
  }

  removeCheckboxFilter(event) {
    this.addLoaderOnTag(event);
    const name = event.params.name;
    const value = event.params.value;

    // We check if we are dealing with a collection of checkboxes or just a simple checkbox
    if (document.querySelector(`input[type="checkbox"][name="${name}"]`).length > 1) {
      // get the input with name and value
      document.querySelector(`input[type="checkbox"][name="${name}"][value="${value}"]`).click();
    } else {
      // get the index 1 as there should be a hidden input on index 0
      document.getElementsByName(name)[1].click();
    }
  }

  removeRadioFilter(event) {
    this.addLoaderOnTag(event);

    const emptyValueRadio = this.findFirstEmptyRadio(event.params.name);
    if (emptyValueRadio !== null) {
      emptyValueRadio.click();
    }
  }

  removeFilter(event) {
    this.addLoaderOnTag(event);
    this.changeValueForElement(event.params.name, event.params.defaultValue);
    this.submitForm();
  }

  /**
   * Get the first form element with the name and change the value
   * Warning: it may be an input of type hidden or text as in some cases
   * the only input to be present for an attribute is a hidden one
   * @param inputName
   * @param value
   */
  changeValueForElement(inputName, value = '') {
    const input = document.getElementsByName(inputName)[0];
    input.value = value;
  }

  addLoaderOnTag(event) {
    const filter = event.currentTarget;
    filter.closest('.tag--with-loader').querySelector('.tag__loader').classList.add('tag__loader--show');
  }

  submitForm() {
    // this.addLoaderToFilterButtons();
    this.showModalLoader();
    this.filterFormTarget.requestSubmit();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  /**
   * Intercepts all events already existing on inputs (e.g. radio buttons, checkboxes, etc...) and
   * add event to submit form after all events have been completed
   */
  interceptAndAddSubmitEvent() {
    const eventPromise = new Promise((resolve) => {
      this.inputTargets.forEach((element) => {
        element.addEventListener('change', () => {
          resolve();
        });
      });
    });

    // Wait for all event Promises to resolve
    eventPromise.then(() => {
      this.submitForm();
    });
  }

  /**
   * Find the first input that is not hidden and empty in list of radio inputs
   * @return {null|*}
   * @param inputName
   */
  findFirstEmptyRadio(inputName) {
    const inputs = document.getElementsByName(inputName);
    for (let i = 0; i < inputs.length; i++) {
      const input = inputs[i];

      // Check if the input is not hidden and has an empty value
      if (input.type !== 'hidden' && input.value.trim() === '') {
        return input;
      }
    }

    return null;
  }

  resetSearch({ params: { modal } }) {
    this.showLoaderEraseButton();
    if (modal) {
      this.showModalLoader();
    }

    this.resetFormTarget.requestSubmit();
  }

  submitWithSort(event) {
    // if we want to show a specific message when clicking on the distance filter
    // if (!event.params.submit && event.target.value === 'distance') {
    //   const formElement = event.target.closest('.search-sort-by');
    //   const popover = Popover.getOrCreateInstance(formElement, {
    //     content: $('.search-result__sort-error-message').html(),
    //     trigger: 'manual',
    //     placement: 'bottom',
    //     sanitize: false,
    //     html: true,
    //   });
    //
    //   popover.show();
    //
    //   $('.search-result__sort-error-message-close').click(() => {
    //     popover.hide();
    //   });
    // } else {
    $('.search-filter-sort-by').val(event.target.value);
    this.submitForm();
  }

  displayModeActions(event) {
    const value = event.currentTarget.value;

    // save the current display mode in the forms
    $('.search-filter-display-mode').val(value);

    this.changeUrlDisplayMode(value);

    // change the value of all the display mode inputs
    $(`input[value="${value}"].search__display-mode`).click();
  }

  addLoaderToFilterButtons() {
    const $filterButtons = $('.search-filter-button');
    // remove ability to trigger the action more time
    $filterButtons.css('pointer-events', 'none');
    $filterButtons.find('svg').remove();
    $filterButtons.prepend("<span class='spinner-border spinner-border-sm me-2' role='status'></span>");
    $filterButtons.addClass('btn--disabled');
  }

  showLoaderEraseButton() {
    const $button = $('#search-selected-filters .erase-all');
    $button.css('pointer-events', 'none');
    $button.find('svg').hide();
    $('.erase-all__loading-icon').show();
  }

  showModalLoader() {
    $('.search-filters__loader').addClass('content-loader--visible');
  }

  /**
   * Modify the display mode param in the url
   * @param value
   */
  changeUrlDisplayMode(value) {
    const url = new URL(window.location.href);

    // we need to specify the form name with the param as it is the param that is being used
    url.searchParams.set('s[display_mode]', value);

    window.history.replaceState({}, '', url);
  }

  changeDistance(elem) {
    const $element = $(elem);
    const distance = $element.val();

    const $distanceHiddenInput = $('.search-filter-distance');
    const modal = $element.data('modal');

    if (this.distanceValue === distance) {
      return;
    }
    this.distanceValue = distance;

    if (!modal) {
      this.distanceLoader($element);
    }

    $distanceHiddenInput.val(distance);

    if (modal) {
      this.showModalLoader();
    }

    this.filterFormTarget.requestSubmit();
  }

  distanceLoader($element) {
    const $parent = $element.parent();
    const $distanceFilters = $('.search-distance-filters').children('div');

    $('.search-result__distance-filter-icon').hide();
    $('.search-result__distance-filter-loader').addClass('search-result__distance-filter-loader--visible');

    // remove all click events on the distance filters
    $distanceFilters.css('pointer-events', 'none');

    // we replace the text of the button
    const distanceText = $parent.children('label').html();
    $('.search-result__selected-max-distance').html(distanceText);

    // close the popover
    Popover.getOrCreateInstance('.search-result__distance-button').hide();

    // remove loader and re enable all click after 10secs in case the ajax failed
    setTimeout(() => {
      $distanceFilters.css('pointer-events', 'initial');
      $('.search-result__distance-filter-loader').removeClass('search-result__distance-filter-loader--visible');
      $('.search-result__distance-filter-icon').show();
    }, 10000);
  }
}
