// this is to keep a reference to each event listener registered to a dom element

// make original addEventListener et removeEventListener 'private' to use it later
EventTarget.prototype._addEventListener = EventTarget.prototype.addEventListener;
EventTarget.prototype._removeEventListener = EventTarget.prototype.removeEventListener;
// now override addEventListener in order to add eventListenerList hash on the event receiver
EventTarget.prototype.addEventListener = function(eventName, listenerFunction, options) {
  // clean up options
  if (options === undefined) options = false;
  // on global scope this is undefined ...
  const that = this || window;
  // start with calling super function in order to prevent possible js crash
  that._addEventListener(eventName, listenerFunction, options);
  // make sure eventListenerList is exist
  if (!that.eventListenerList) that.eventListenerList = {};
  // make sure eventListenerList[eventName] is exist
  if (!that.eventListenerList[eventName]) that.eventListenerList[eventName] = [];
  // register the event with the listener and flags options
  that.eventListenerList[eventName].push({ listener: listenerFunction, options });
};
EventTarget.prototype.removeEventListener = function(eventName, listenerFunction, options) {
  // clean up options
  if (options === undefined) options = false;
  // on global scope this is undefined ...
  const that = this || window;
  // start with calling super function in order to prevent possible js crash
  that._removeEventListener(eventName, listenerFunction, options);
  // if event is found in eventListenerList
  if (that.eventListenerList && that.eventListenerList[eventName]) {
    // then try to find the listener on eventListenerList
    for (const index in that.eventListenerList[eventName]) {
      // remove it only if same listener and same options
      if (that.eventListenerList[eventName][index].listener === listenerFunction && that.eventListenerList[eventName][index].options === options) {
        //                console.log("try to remove event : "+eventName);
        that.eventListenerList[eventName].splice(index, 1);
        return;
      }
    }
  }
};

// return all event listener binded on a dom element  ex : document.getEventListeners()
EventTarget.prototype.getEventListeners = function(eventName) {
  // on global scope this is undefined ...
  const that = this || window;
  if (!that.eventListenerList) that.eventListenerList = {};
  // if no eventName return all the list
  if (eventName === undefined) {
    return that.eventListenerList;
  }
  // otherwise return listener binded on this event
  return that.eventListenerList[eventName];
};
