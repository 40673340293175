import breakpointHelper from 'breakpoint-helper';

const bpHelper = breakpointHelper({
  xs: '320px',
  hdm: '385px',
  sm: '520px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1920px',
});

function randomString(size = 11) {
  let text = Math.random().toString(36).substr(2);
  while (text.length < size) {
    text += Math.random().toString(36).substr(2);
  }
  return text.substr(0, size);
}

const cleanDoubleWhiteSpace = function(string) {
  const regex = /\s{2,}/gm;
  return string.replace(regex, ' ').trim();
};

const removeParamsFromUrl = function(paramsToRemove) {
  // Convertir une chaîne en tableau si nécessaire
  if (typeof paramsToRemove === 'string') {
    paramsToRemove = [paramsToRemove];
  }

  const url = new URL(window.location.href);
  let paramsFound = false;

  paramsToRemove.forEach(param => {
    if (url.searchParams.has(param)) {
      url.searchParams.delete(param);
      paramsFound = true;
    }
  });

  // Ne rien faire si aucun paramètre n'a été trouvé et supprimé
  if (!paramsFound) {
    return;
  }

  // Construire la nouvelle URL sans les paramètres supprimés
  let newUrl = url.origin + url.pathname;
  if (url.searchParams.toString()) {
    newUrl += '?' + url.searchParams.toString();
  }

  // Mettre à jour l'URL affichée sans modifier l'historique
  window.history.replaceState({}, document.title, newUrl);
};
global.removeParamsFromUrl = removeParamsFromUrl;
export { bpHelper, randomString, cleanDoubleWhiteSpace, removeParamsFromUrl };
