import './autotrim';
import './checkbox';
import './copy';
import './dates_pickers';
import './disable_with';
import './enter_as_tab';
import './errors';
import './input-group';
import './magic_wand';
import './only-decimal';
import './only-integer';
import './password';
import './phone-number';
import './radio-button';
import './select';
import './time';
import {scrollToItem} from "./scroll_on_focus";

global.scrollToItem = scrollToItem;