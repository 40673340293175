import { scrollToItem } from './scroll_on_focus';

document.arrive('form select.is-invalid, form input.is-invalid, form .invalid-feedback, form textarea.is-invalid, form .input-group.is-invalid', function(element) {
  setTimeout(() => { checkElementForErrorRefresh(element); }, 1);
});

function checkElementForErrorRefresh(element) {
  const $element = $(element);
  const $formInput = ($element[0].nodeName === 'DIV' ? $element.find('input, select, textarea') : $element);
  if ($element.hasClass('is-invalid')) {
    const error = $element.parents('.wrapper_input').find('.invalid-feedback');
    const actualValue = getInputValue($formInput);
    const eventName = ($formInput.hasClass('date_picker') ? 'changeDate' : 'change');

    function checkForError() {
      if (getInputValue($formInput) === actualValue) {
        addInvalidClass($element);
        error.show();
      } else {
        removeInvalidClass($element);
        error.hide();
      }
      checkForErrorRefresh($element);
    }

    $formInput.off(eventName, checkForError);
    $formInput.on(eventName, checkForError);
  } else if ($element.hasClass('invalid-feedback')) {
    $element.on('visibilityChanged', function() {
      checkForErrorRefresh($element);
    });
    checkForErrorRefresh($element);
  }
}

function addInvalidClass($element) {
  $element.addClass('is-invalid');
}

function removeInvalidClass($element) {
  $element.parents('.wrapper_input').find('.is-invalid').removeClass('is-invalid');
}

function getInputValue($element) {
  if ($element.filter('[type="radio"]').length > 0) {
    return $("input[name='" + $element[0].name + "']:checked").val();
  } else if ($element.filter('[type="checkbox"]').length > 0) {
    let result = '';
    $("input[name='" + $element[0].name + "']:checked").each(function(i) {
      result += this.value + '|';
    });
    return result;
  } else {
    if ($element.hasClass('only-numeric') || $element.hasClass('only-decimal')) {
      return Number($element.val().replace(',', '.'));
    }
    return $element.val();
  }
}

function checkForErrorRefresh($element) {
  const $unvalidatableParent = $element.parents('.unvalidatable').first();
  if ($unvalidatableParent.length > 0) {
    refreshInvalidatableSegment($unvalidatableParent);
  }
  scrollToError();
}

function refreshInvalidatableSegment($item) {
  const selector = '.is-invalid:visible:not(.unvalidatable), .invalid-feedback:visible:not(.segment), .invalid-feedback:not(.segment)[style="display: block;"], .accordion-body .invalid-feedback.d-block:not(.segment)';

  const possibleErrors = $item.find(selector);
  if (possibleErrors.length > 0) {
    $item.addClass('is-invalid');
  } else {
    $item.removeClass('is-invalid');
  }
}

document.arrive('.unvalidatable', function(element) {
  const $element = $(element);
  refreshInvalidatableSegment($element);
});

function executeScrollToError() {
  const $errorField = $('.unvalidatable.is-invalid, .is-invalid:visible').first();
  scrollToItem($errorField, function() {
    let item = $errorField;
    if ($errorField.hasClass('unvalidatable')) {
      item = $errorField.find('.is-invalid').first();
    }
    item.focus();
  }, false);
  // if ((typeof errorField.offset() !== 'undefined')) {
  //     const $parent = findScrollableParent(errorField);
  //     console.log("scrollable parent : ");
  //     console.log($parent);
  //     let scrollPosition = errorField.offset().top + 100 - (window.innerHeight / 2) - $parent.children().first().offset().top;
  //
  //     $parent.animate({
  //         scrollTop: scrollPosition
  //     }, 'slow','linear', ()=>{
  //         let item = errorField
  //         if( errorField.hasClass("unvalidatable") ){
  //             item = errorField.find(".is-invalid").first();
  //         }
  //         item.focus();
  //     });
  // }
}

function scrollToError() {
  clearTimeout(window.scrollToErrorTimeout);
  window.scrollToErrorTimeout = setTimeout(executeScrollToError, 200);
}
