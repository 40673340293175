import { setEnableLabel } from '../../design_system/inputs/disable_with';

async function autoCompleteLocationIq(options) {
  const autoCompleteLocationIqModule = await import('../../map/locationiq_autocomplete');
  return autoCompleteLocationIqModule.autoCompleteLocationIq(options);
}

class MarketPlaceSearch {
  constructor(form, locationiqPublicMapApiKey, searchableCountryCodes) {
    this.form = $(form);
    const self = this; // Conserver une référence à 'this'
    autoCompleteLocationIq({
      selector: `#${this.form.attr('id')} input[name='s[geo_search]']`,
      apiKey: locationiqPublicMapApiKey,
      countryCode: searchableCountryCodes,
      on_select_callback: (selection) => {
        self.setGeofields(selection);
      },
    });
    this.geoStreetNumber = this.form.find("input[name='s[geo_latitude]']");
    this.geoLatitude = this.form.find("input[name='s[geo_latitude]']");
    this.geoLongitude = this.form.find("input[name='s[geo_longitude]']");
    this.geoLocality = this.form.find("input[name='s[geo_locality]']");
    this.geoRoute = this.form.find("input[name='s[geo_route]']");
    this.geoAdministrativeAreaLevel2 = this.form.find("input[name='s[geo_administrative_area_level_2]']");
    this.geoAdministrativeAreaLevel1 = this.form.find("input[name='s[geo_administrative_area_level_1]']");
    this.geoCountry = this.form.find("input[name='s[geo_country]']");
    this.geoPostalCode = this.form.find("input[name='s[geo_postal_code]']");
    this.geoType = this.form.find("input[name='s[geo_type]']");
    this.selectedValue = this.geoSearchValue();
    this.displayError(true);
    this.form.on('submit', function(event) {
      if (!self.canSubmit()) {
        event.preventDefault(); // Empêche la soumission du formulaire
        setTimeout(() => {
          setEnableLabel(self.form.find('[data-turbo-disable-with]'));
          // do it in timeout in order to simulate loading for trust
          self.displayError();
        }, 100);
      }
    });
  }

  setGeofields(result) {
    // structure du result :
    // attention result.address donne le contenu de l'adresse en cas d'adresse complète :
    // et le result.result en donne un résultat plus léger :
    /// //////////////////////////////////////////////////////
    // {
    //   "place_id": "323611124648",
    //   "osm_id": "5840044234",
    //   "osm_type": "node",
    //   "licence": "https://locationiq.com/attribution",
    //   "lat": "48.7971289",
    //   "lon": "2.1584233",
    //   "boundingbox": [
    //   "48.7970789",
    //   "48.7971789",
    //   "2.1583733",
    //   "2.1584733"
    // ],
    //   "class": "place",
    //   "type": "house",
    //   "display_name": "53, Rue Pierre Curie, Porchefontaine, Versailles, Yvelines, Île-de-France, 78000, France",
    //   "display_place": "Rue Pierre Curie",
    //   "display_address": "53, Porchefontaine, Versailles, Yvelines, Île-de-France, 78000, France",
    //   "address": {
    //   "name": "Rue Pierre Curie",
    //     "house_number": "53",
    //     "road": "Rue Pierre Curie",
    //     "suburb": "Porchefontaine",
    //     "city": "Versailles",
    //     "county": "Yvelines",
    //     "state": "Île-de-France",
    //     "postcode": "78000",
    //     "country": "France",
    //     "country_code": "fr"
    // },
    //   "result": {
    //   "address": "53 Rue Pierre Curie",
    //     "postcode": "78000",
    //     "city": "VERSAILLES"
    // },
    //   "display": "53 Rue Pierre Curie, 78000 VERSAILLES"
    // }

    /// /////////////////////////////////////////////////////////////////////////
    // Par contre en cas de requete sur une ville seule on a ceci :
    /// /////////////////////////////////////////////////////////////////////////
    // {
    //   "place_id": "323889249859",
    //   "osm_id": "30295",
    //   "osm_type": "relation",
    //   "licence": "https://locationiq.com/attribution",
    //   "lat": "48.8035403",
    //   "lon": "2.1266886",
    //   "boundingbox": [
    //   "48.7792385",
    //   "48.8286153",
    //   "2.0699384",
    //   "2.1683504"
    // ],
    //   "class": "place",
    //   "type": "town",
    //   "display_name": "Versailles, Yvelines, Île-de-France, 78000, France",
    //   "display_place": "Versailles",
    //   "display_address": "Yvelines, Île-de-France, 78000, France",
    //   "address": {
    //   "name": "Versailles",
    //     "county": "Yvelines",
    //     "state": "Île-de-France",
    //     "postcode": "78000",
    //     "country": "France",
    //     "country_code": "fr"
    // },
    //   "result": {
    //   "address": "",
    //     "postcode": "78000",
    //     "city": "VERSAILLES"
    // },
    //   "display": "78000 VERSAILLES"
    // }
    const mainResult = result.result;
    const address = result.address;
    const geoValues = {
      latitude: result.lat,
      longitude: result.lon,
      locality: mainResult.city,
      route: mainResult.address,
      street_number: '',
      administrative_area_level_2: address.state,
      administrative_area_level_1: address.suburb,
      country: address.country_code,
      postal_code: mainResult.postcode,
    };
    this.geoStreetNumber.val(geoValues.street_number);
    this.geoLatitude.val(geoValues.latitude);
    this.geoLongitude.val(geoValues.longitude);
    this.geoLocality.val(geoValues.locality);
    this.geoRoute.val(geoValues.route);
    this.geoAdministrativeAreaLevel2.val(geoValues.administrative_area_level_2);
    this.geoAdministrativeAreaLevel1.val(geoValues.administrative_area_level_1);
    this.geoCountry.val(geoValues.country);
    this.geoPostalCode.val(geoValues.postal_code);
    this.geoType.val(geoValues.type);
    this.selectedValue = this.geoSearchValue();
    // on select refresh error status
    // this.displayError(false, true);
  }

  displayError(allowEmpty = false, forceHide = false) {
    const $error = this.geoSearch().closest('.wrapper_input').find('.invalid-feedback');
    if (forceHide || this.canSubmit() || (allowEmpty && this.geoSearch().val() === '')) {
      this.geoSearch().removeClass('is-invalid');
      $error.hide();
    } else {
      this.geoSearch().addClass('is-invalid');
      $error.show();
    }
  }

  geoSearch() {
    return this.form.find("input[name='s[geo_search]']");
  }

  canSubmit() {
    if (this.geoSearch().val() === '') return false;
    return this.selectedValue === this.geoSearchValue() && Number(this.geoLatitude.val()) !== 0;
  }

  geoSearchValue() {
    return this.geoSearch().val().trim();
  }
}

export const initMarketplaceSearch = (selector, locationiqPublicMapApiKey, searchableCountryCodes) => {
  document.arrive(selector, { existing: true, once: true }, (element) => {
    document.unbindArrive(selector);
    new MarketPlaceSearch($(element).closest('form'), locationiqPublicMapApiKey, searchableCountryCodes);
  });
};

const hideSubcategories = (autoSelect = true) => {
  const subcategoryOptionsClassSelector = `.${$('.search-band__category').val()}`;
  $(`.search-band__subcategory option${subcategoryOptionsClassSelector}`).show().addClass('category-visible');
  $('.search-band__subcategory option').not(subcategoryOptionsClassSelector).hide().removeClass('category-visible');

  if (autoSelect) {
    const firstNotHiddenOption = $('.search-band__subcategory').find('option.category-visible:first');
    firstNotHiddenOption.prop('selected', true);
  }
};

document.addEventListener('turbo:load', function() {
  // Execute the first time the page load
  hideSubcategories(false);

  $(document).on('change', '.search-band__category', hideSubcategories);
});

// Execute if the category form have errors and is re rendered
document.addEventListener('turbo:frame-render', () => hideSubcategories(false));
