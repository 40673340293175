let isAnimating = false;
let currentView = '';
let currentUniverse = '';

// le lateral menu étant en data-permanent, il ne faut pas binder les events au turbo-load sinon ils sont bindés de multiple fois
$(document).ready(function() {
  currentUniverse = $('#lateral-menu.app-menu').data('started-universe');

  $('.sidebar__container__content__universes .universe--link').click(function() {
    const clickedUniverse = $(this).data('universe'); // get id of clicked universe
    selectLateralMenu(clickedUniverse, 'home');
  });

  $('#lateral-menu.app-menu a.item').off('click').on('click', function() {
    const $item = $(this);
    const universe = $item.parents('.current-universe').data('universe');
    selectLateralMenu(universe, $item.data('view'));
  });

  $('#lateral-menu.app-menu a.universe-account-direct-link').click(function() {
    $('#lateral-menu a.item').removeClass('clicked active');
    $('.universe').removeClass('active');
    $(this).addClass('clicked active');
    $('.sidebar__container__content__universes .universe--switch').removeClass('current').show();
    $('.sidebar__container__content__universes .current-universe').slideUp(300);
    currentUniverse = '';
    currentView = 'dashboard';
  });
});

function selectLateralMenu(universe, view) {
  // console.log('Selecting universe: ' + universe + ' and view: ' + view + ' Current-universe is: ' + currentUniverse);
  if (view === '') {
    $('#lateral-menu a.item').removeClass('clicked active');
  }

  if (view === currentView && universe === currentUniverse) {
    // console.log('Universe and view already set');
    return;
  }

  currentView = view;

  openUniverseMenu(universe, () => {
    $('#lateral-menu a.item').removeClass('clicked active');

    if (universe === 'account') {
      $('.universe').removeClass('active');
      $('#lateral-menu a.universe-account').addClass('clicked active');
    } else {
      $('#lateral-menu a.universe-account').removeClass('clicked active');
    }

    if (currentUniverse === '') {
      return;
    }

    const $selectView = $('#lateral-menu .current-universe.' + currentUniverse + " a.item[data-view='" + currentView + "']");
    if ($selectView.length === 0) {
      console.error('universe: ' + currentUniverse + ' -> view: ' + currentView + ' not found');
    }
    $selectView.addClass('clicked active');
  });
}

let timeoutAnim1 = null;
let timeoutAnim2 = null;

function openUniverseMenu(universe, callback) {
  isAnimating = true;
  // si on veut le meme universe => pas d'anim ou on laisse l'anim en cours
  if (currentUniverse === universe) {
    callback();
    return;
  }
  currentUniverse = universe;

  $('#lateral-menu .current-universe a.item').removeClass('active');

  // cache le menu du bas
  $('.sidebar__container__content__universes .universe--switch').hide();
  // on s'assure que le menu souhaité est bien visible (meme dans le menu caché juste avant)
  $(".universe--link[data-universe='" + currentUniverse + "']").show();
  // on referme tout les menus sur eux
  $('.sidebar__container__content__universes .current-universe').slideUp(300);

  if (isAnimating) {
    if (timeoutAnim1 && timeoutAnim2) {
      // on laise faire l'anim d'avant elle sera sur le bon univers
      return;
    } else if (timeoutAnim2) {
      // on annule l'anim 2 et on refait le tout
      clearTimeout(timeoutAnim2);
      timeoutAnim2 = null;
    }
  }
  timeoutAnim1 = setTimeout(function() {
    timeoutAnim1 = null;
    $(".universe--link[data-universe='" + currentUniverse + "']").addClass('fixed').fadeOut(200);

    // on hide celui qui est affiché
    $('.sidebar__container__content__universes .current-universe').removeClass('displayed');
    // on affiche et deplie le menu souhaité
    $('.sidebar__container__content__universes .current-universe.' + currentUniverse).addClass('displayed').slideDown(300);
  }, 400);

  timeoutAnim2 = setTimeout(function() {
    timeoutAnim2 = null;
    callback();
    // on affiche le menu du bas avec le choix des universe
    // laisser le removeAttr qui enleve le display:inline mis par le show
    $('.sidebar__container__content__universes .universe--switch').removeClass('current').show().removeAttr('style');
    // en cas de plusieurs anim en meme temps, on est sur de les remettre a leur place
    $('.universe--link').removeClass('fixed');
    // on hide celui en cours
    $(".universe--link[data-universe='" + currentUniverse + "']").addClass('current').hide();
    // on cache pour ouvrir le menu du bas
    if ($('#lateral-menu').hasClass('fxp-sidebar-open')) {
      $('.bottom-links').hide().slideDown(200, 'swing', () => {
        isAnimating = false;
      });
    }
  }, 600);
}

global.selectLateralMenu = selectLateralMenu;

document.addEventListener('turbo:before-cache', function() {
  $('.bottom-links').removeAttr('style');
});

// this is to catch some animation on navigation, and also try to fix menu on history navigation (previous/next on browser)
document.addEventListener('turbo:visit', function(event) {
  const pathname = event.detail.url.replace(window.location.origin, '');

  if (pathname.startsWith('/accueil-gestion')) {
    selectLateralMenu('child_management', 'home');
  } else if (pathname.startsWith('/accueil-quotidien')) {
    selectLateralMenu('child_notebook', 'home');
  } else if (pathname.startsWith('/dashboard')) {
    selectLateralMenu('', 'dashboard');
  }
});
