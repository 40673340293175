import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'selectedIcon', 'selectedText', 'selectOptions'];

  connect() {
    this.hideOptionsOnOutsideClick();
  }

  select({ params: { value, text, icon } }) {
    if (value === this.inputTarget.value) {
      this.selectOptionsTarget.style.display = 'none';
      return;
    }
    this.inputTarget.value = value;
    if (icon !== '') {
      this.selectedIconTarget.innerHTML = icon;
    }
    this.selectedTextTarget.innerHTML = text;
    this.selectOptionsTarget.style.display = 'none';
    this.addActiveClassToSelected();

    // dispatch a change event on the hidden input so that it can be caught by another script if necessary
    const event = new Event('change');
    this.inputTarget.dispatchEvent(event);
    // also trigger the event in jquery for more options
    $(this.inputTarget).trigger('change');
  }

  showOptions(event) {
    if (!$(this.selectOptionsTarget).is(':visible') && !this.selectOptionsTarget.contains(event.target)) {
      // opens it on a timeout as the global click event will get triggered too at the same time
      setTimeout(() => {
        this.selectOptionsTarget.style.display = 'flex';
      }, 50);
    }
  }

  hideOptionsOnOutsideClick() {
    document.addEventListener('click', (event) => {
      if (!this.selectOptionsTarget.contains(event.target) && $(this.selectOptionsTarget).is(':visible')) {
        this.selectOptionsTarget.style.display = 'none';
      }
    });
  }

  addActiveClassToSelected() {
    const options = this.selectOptionsTarget.children;

    for (let i = 0; i < options.length; i++) {
      if (options[i].dataset.selectIconValueParam === this.inputTarget.value) {
        $(options[i]).addClass('select-icon__option-active');
      } else {
        $(options[i]).removeClass('select-icon__option-active');
      }
    }
  }
}
