document.addEventListener('turbo:load', function() {
  $('a.add-pictures-btn').on('click', function() {
    import('./uploader').then(module => module.onMarketPlaceListingLoad());
  });
});
const reloadPictureMasonry = (time = 100) => {
  setTimeout(function() {
    const $rowMasonry = $('.row--masonry');
    if ($rowMasonry.data('masonry')) {
      $rowMasonry.masonry('reloadItems');
      $rowMasonry.masonry('layout');
    }
  }, time);
};

global.reloadPictureMasonry = reloadPictureMasonry;