const findScrollableParent = function($item) {
  // while($item.length > 0 && $item[0].nodeName != 'BODY' && $item[0].nodeName != 'HTML' && !(($item.css('overflow-y') == 'auto' || $item.css('overflow-y') == 'scroll') && $item[0].scrollHeight > $item[0].clientHeight) )
  while ($item.length > 0 && $item[0].nodeName !== 'HTML' && !(($item.css('overflow-y') === 'auto' || $item.css('overflow-y') === 'scroll') && $item[0].scrollHeight > $item[0].clientHeight)) {
    $item = $item.parent();
  }
  return $item.length > 0 ? $item : $('html');
};

// scroll vers un item en faisant scrollé tout les elements scrollable pour etre sûr que l'element soit sur l'ecran
// top a true pour forcer le scroll en top position de l'ecran
const scrollToItem = function($item, callback = null, top = true) {
  if(!$item){
    return;
  }
  if ((typeof $item.offset() !== 'undefined') && !$item.isInViewport(top ? 64 : -1)) {
    const $parent = findScrollableParent($item);
    if ($item[0].nodeName !== 'HTML') {
      // scroll parent too
      scrollToItem($parent, null, top);
    }
    let scrollPosition = 0;
    if (top) {
      // On décale de 60 px pour prendre en compte la hauteur de la Navbar flottante en mobile
      scrollPosition = $item.offset().top - 60 - $parent.children().first().offset().top;
    } else {
      scrollPosition = $item.offset().top + 100 - (window.innerHeight / 2) - $parent.children().first().offset().top;
    }

    $parent.animate({
      scrollTop: scrollPosition,
    }, 'slow', 'linear', () => {
      if (callback) {
        callback();
      }
    });
  } else {
    if (callback) {
      callback();
    }
  }
};

const initAutoScollFocus = function() {
  const scrollTo = function scrollTo($item) {
    if (window.autoScollFocus) {
      return;
    }
    window.autoScollFocus = true;
    scrollToItem($item, null, false);
    setTimeout(() => { window.autoScollFocus = false; }, 10);
  };

  const onFocusOutInput = function(e) {
    const $obj = $(e.target);
    if (e.type === 'focusin') {
      scrollTo($obj);
    }
  };

  $(document).off('focus blur', 'select, textarea, input[type=text], input[type=date], input[type=password], input[type=email], input[type=number]', onFocusOutInput);
  $(document).on('focus blur', 'select, textarea, input[type=text], input[type=date], input[type=password], input[type=email], input[type=number]', onFocusOutInput);

  const onFocusOutDatePicker = function(e) {
    // const $obj = $(this);
    if (e.type === 'focusin') {
      setTimeout(function() {
        if ($('.datetimepicker div:visible').length > 0) {
          // $('.datetimepicker div:visible')[0].scrollIntoViewIfNeeded();
          scrollTo($('.datetimepicker div:visible').first());
        }
      }, 200);
    }
  };

  $(document).off('focus blur', 'input[type=year_first_date_picker], input[type=datetime_picker], input[type=date_picker]', onFocusOutDatePicker);
  $(document).on('focus blur', 'input[type=year_first_date_picker], input[type=datetime_picker], input[type=date_picker]', onFocusOutDatePicker);
};

document.addEventListener('turbo:load', initAutoScollFocus);

export { scrollToItem };
