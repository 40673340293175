import { callTurboStream } from '../../components/turbo';
import Collapse from 'bootstrap/js/dist/collapse';
import { bpHelper } from '../../components/utils';

document.arrive('#report-content', function() {
  // delais due to turbo cache page
  setTimeout(() => { document.addEventListener('journeysChange', refreshReportDay); }, 1);
});

document.leave('#report-content', function() {
  document.removeEventListener('journeysChange', refreshReportDay);
});

// document.arrive('.report-accordion__line', function()
$(document).on('click', '.report-accordion__line-header', function(event) {
  // console.log(event, $(event.currentTarget).parent())
  const noCollapse = $(event.target).parents('.prevent_accordion').length > 0 &&
      ($(event.target).parents('.btn').length > 0 || event.target.classList.contains('btn'));

  if (bpHelper.isMatching('lg', true) && !noCollapse) {
    const $collapsibleElement = $(event.currentTarget).parents('.report-accordion__line').find('.report-container');
    const $accordionLine = $(event.currentTarget).parents('.report-accordion__line.accordion-item');
    const $iconClosed = $(event.currentTarget).find('.report-accordion__line-icon--closed');
    const $iconOpened = $(event.currentTarget).find('.report-accordion__line-icon--opened');

    Collapse.getOrCreateInstance($collapsibleElement).toggle();
    $collapsibleElement.on('hidden.bs.collapse', () => {
      $iconOpened.hide();
      $iconClosed.show();
      $accordionLine.removeClass('accordion-item--active');
    });
    $collapsibleElement.on('shown.bs.collapse', () => {
      $iconOpened.show();
      $iconClosed.hide();
      $accordionLine.addClass('accordion-item--active');

    });
  }
});

function refreshReportDay(event) {
  const frame = document.getElementById('report-content');
  if (!frame) return;
  const child_id = frame.dataset.childId;
  event.detail.modified_dates.forEach(function(date) {
    const url = `/children/${child_id}/contract_days/${date}/completions?origin=month`;
    callTurboStream(url);
  });
}
