import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.locale('fr');
dayjs.extend(utc);
dayjs.extend(timezone);

const dayjsTimezone = (dateTime, keepTime = false) => {
  return dayjs(dateTime).tz('Europe/Paris', keepTime);
};

export { dayjs, dayjsTimezone };
