import { Controller } from '@hotwired/stimulus';
import { bpHelper } from '../../components/utils';
import Collapse from 'bootstrap/js/dist/collapse';

export default class extends Controller {
  static targets = [
    'sticky',
    'stickyContainer',
    'collapseButton',
  ];

  connect() {
    // as the profile is used on the profile page and the conversation and we don't need it on the conversation
    // if (this.element.dataset.pageType !== 'conversation') {
    //   this.stickyTop();
    // }
    this.manageCollapse();
  }

  stickyTop() {
    window.addEventListener('scroll', () => {
      const rect = this.stickyContainerTarget.getBoundingClientRect();
      const isSticky = rect.top <= 0;
      const stuckClassName = 'listing-profile__sticky--stuck';

      if (isSticky) {
        if (!this.stickyTarget.classList.contains(stuckClassName)) {
          this.stickyTarget.classList.add(stuckClassName);
        }
      } else {
        this.stickyTarget.classList.remove(stuckClassName);
      }
    });
  }

  manageCollapse() {
    if (bpHelper.isMatching('md', true)) {
      this.collapseButtonTargets.forEach(function(target) {
        const collapsible = document.getElementById(target.dataset.bsTarget);
        const parentSegment = target.closest('.section--marketplace-listing-accordion');
        const collapsedClass = 'collapsed';
        const sectionModifierClass = 'section--opened';

        collapsible.addEventListener('show.bs.collapse', () => {
          target.classList.remove(collapsedClass);
          parentSegment.classList.add(sectionModifierClass);
        });

        collapsible.addEventListener('hide.bs.collapse', () => {
          target.classList.add(collapsedClass);
          parentSegment.classList.remove(sectionModifierClass);
        });

        Collapse.getOrCreateInstance(document.getElementById(target.dataset.bsTarget)).hide();

        target.addEventListener('click', function() {
          Collapse.getOrCreateInstance(document.getElementById(this.dataset.bsTarget)).toggle();
        });
      });
    }
  }

  openDescriptionCollapse(event) {
    if (bpHelper.isMatching('md', true)) {
      const collapse = event.target.closest('.collapse');
      Collapse.getOrCreateInstance(collapse).toggle();
    }
  }
}
