import { Controller } from '@hotwired/stimulus';
import { bpHelper } from '../../components/utils';

const animationPreloadState = 'search-address-form--preload-state';
const animationStartState = 'search-address-form--animation-start';
const animationEndState = 'search-address-form--animation-end';
const resultListClass = '.autocomplete-address-result';
const hiddenInputsValues = [];
const manualSearchValues = [];
let currentValidState = true;

export default class extends Controller {
  static targets = [
    'addressSearch',
    'closeListButton',
    'addressInput',
    'formMessage',
  ];

  connect() {
    this.hiddenInputs = document.getElementById('geo-location-fields').querySelectorAll('input');
    this.listLoaderTarget = JSON.parse(this.addressSearchTarget.dataset.listLoader);

    // to avoid some list element to be hidden(on the signup flow)
    $(this.addressSearchTarget.closest('.modal-body')).css('min-height', '500px');

    if (this.hasManualSearch()) {
      this.manualInputs = document.querySelector('.manual-search').querySelectorAll('input');
    }

    // undefined because will execute on a filled input
    this.loaderTimeout = undefined;
    this.noResultTimeout = undefined;
  }

  onFinishTyping() {
    return () => {
      // clearTimeout is necessary to prevent icon animation from breaking
      // the clearTimeout does nothing on an undefined argunment
      clearTimeout(this.loaderTimeout);
      clearTimeout(this.noResultTimeout);
      this.loaderTimeout = setTimeout(() => {
        if ($(this.addressInputTarget).val().length > 2) {
          // results loads in around 500ms(around 300 when cached)
          this.showLoader();
        }
      }, 250);

      // for UX purpose, loader should display a no result message after a while
      this.noResultTimeout = setTimeout(() => {
        this.showNoResultMessage();
      }, 15000);
    };
  }

  onCancelClick() {
    this.revertInputValue();
    this.revertInputPosition();
    // pour empecher la liste de s'afficher sur desktop(sans modal) apres un cancel
    if (!this.isInModal() && !this.isMobile()) {
      this.hideList();
    }
    $(this.formMessageTarget).hide();
    this.handleErrorMessage();
  }

  onInputEnter(event) {
    this.resultList = this.element.querySelector(resultListClass);

    $(this.addressInputTarget).unbind('keydown');

    if (this.closeListButtonTarget.classList.contains('hide')) {
      this.saveCurrentValue();
      this.clearInputValue();
    }

    if (this.isInModal()) {
      $(this.formMessageTarget).show();
    }

    if (this.isMobile() || this.isInModal()) {
      if (!this.addressSearchTarget.classList.contains(animationPreloadState)) {
        this.preloadAnimation();
        this.hideResults();
      }

      this.showList();
      this.addressSearchTarget.classList.remove('mt-3');

      if (this.isMobile()) {
        $('.modal-header').slideUp(300);
        if (!this.isInModal()) {
          window.scrollTo(0, 0);
        }
        this.preventScrollOnMobile();
      }
      this.animateInputSlideUp();

      // empeche que la liste disparaisse en cas de focus out de la fenetre
      window.addEventListener('blur', () => {
        if (!this.closeListButtonTarget.classList.contains('hide')) {
          this.showList();
        }
      }, { once: true });
    } else {
      // pour afficher la liste correctement en desktop(sans modal) sans casser l'animation en modal + mobile
      this.showList();
    }

    this.addressInputTarget.addEventListener('input', this.onFinishTyping());
    this.preventListClose();
    this.handleOnFocusOut(event);
    this.closeListButtonTarget.classList.remove('hide');
    this.addressInputTarget.classList.add('focused');
    this.addressSearchTarget.dataset.validState = 'false';
  }

  handleOnFocusOut(event) {
    event.stopPropagation();
    document.addEventListener('click', (event) => {
      if ((event.target !== this.closeListButtonTarget || !this.closeListButtonTarget.contains(event.target)) && event.target !== this.addressInputTarget) {
        if (this.isInModal() || this.isMobile()) {
          this.showList();
        } else {
          this.addressInputTarget.value === '' ? this.revertInputValue() : this.saveCurrentValue();
          $(this.formMessageTarget).hide();
          this.hideList();
        }
        this.addressInputTarget.classList.remove('focused');
        if (!this.addressSearchTarget.classList.contains(animationEndState)) {
          this.handleErrorMessage();
          this.closeListButtonTarget.classList.add('hide');
        }
      }
    }, { once: true });
  }

  handleResultOnClick(event) {
    this.saveCurrentValue();
    if (this.addressInputTarget.id !== 'geo-address-city-input') {
      this.addressSearchTarget.dataset.currentValue = event.target.innerText;
    }
    this.addressSearchTarget.dataset.validState = 'true';
    this.setInputValue(this.addressInputTarget, this.addressSearchTarget.dataset.currentValue);
    this.revertInputPosition();
    $(this.formMessageTarget).hide();
    this.hideList();
    this.handleErrorMessage();
    $(this.addressInputTarget).blur();
  }

  preloadAnimation() {
    this.addressSearchTarget.classList.add(animationPreloadState);
    this.addressSearchTarget.classList.add(animationStartState);
  }

  animateInputSlideUp() {
    this.addressSearchTarget.classList.add(animationEndState);
    this.addressSearchTarget.classList.remove(animationStartState);
  }

  animateInputSlideDown() {
    this.addressSearchTarget.classList.remove(animationEndState);
    this.addressSearchTarget.classList.add(animationStartState);
    setTimeout(() => {
      this.hideList();
      this.addressSearchTarget.classList.remove(animationStartState);
      this.addressSearchTarget.classList.remove(animationPreloadState);
    }, 300); // le 300ms permet d'attendre que l'animation de slide down se finit avant de cacher la liste des resultats et remettre l'input a sa place
  }

  preventListClose() {
    const that = this;

    /***
    * Do not use once there because the event should be present at all time except when the preventListClose is
    * called once again. The event 'close' can be called a lot of times before the preventListClose is called again
    ***/
    this.addressInputTarget.removeEventListener('close');
    this.addressInputTarget.addEventListener('close', () => {
      if (!that.closeListButtonTarget.classList.contains('hide')) {
        that.showList();
        that.resultList.removeAttribute('hidden');
      }
    });
  }

  preventScrollOnMobile() {
    this.resultList.addEventListener('touchmove', function(event) {
      event.preventDefault();
    }, false);
  }

  showList() {
    this.changeListSize();

    if (this.isMobile() || this.isInModal()) {
      this.resultList.removeAttribute('hidden');
      this.resultList.classList.add('autocomplete-address-result--show');
    } else {
      this.resultList.classList.add('autocomplete-address-result--no-modal-show');
      this.resultList.classList.add('autocomplete-address-result--border');
    }
  }

  hideList() {
    this.resultList.classList.remove('autocomplete-address-result--show');
    this.resultList.setAttribute('hidden', 'true');
  }

  hideResults() {
    const results = this.resultList.getElementsByTagName('LI');
    for (let i = 0; i < results.length; i++) {
      results[i].style.display = 'none';
    }
  }

  showLoader() {
    this.resultList.removeAttribute('hidden');
    this.resultList.innerHTML = this.listLoaderTarget;
  }

  showNoResultMessage() {
    $(this.resultList).find('p').text(this.addressSearchTarget.dataset.noResultMessage);
    $(this.resultList).find('.icon').hide();
  }

  saveCurrentValue() {
    currentValidState = this.isValidAddress();
    this.addressSearchTarget.dataset.currentValue = this.addressInputTarget.value;
    this.saveHiddenFieldsValue();
  }

  clearInputValue() {
    this.setInputValue(this.addressInputTarget, '');
    this.clearHiddenFieldsValue();
  }

  revertInputValue() {
    this.addressSearchTarget.dataset.validState = currentValidState;
    this.setInputValue(this.addressInputTarget, this.addressSearchTarget.dataset.currentValue);
    this.revertHiddenFieldsValue();
  }

  saveHiddenFieldsValue() {
    for (let i = 0; i < this.hiddenInputs.length; i++) {
      hiddenInputsValues[i] = this.hiddenInputs[i].value;
    }
    if (this.hasManualSearch()) {
      this.saveManualSearchValues();
    }
  }

  clearHiddenFieldsValue() {
    for (let i = 0; i < this.hiddenInputs.length; i++) {
      this.setInputValue(this.hiddenInputs[i], '');
    }

    if (this.hasManualSearch()) {
      this.clearManualSearchValues();
    }
  }

  revertHiddenFieldsValue() {
    for (let i = 0; i < this.hiddenInputs.length; i++) {
      this.setInputValue(this.hiddenInputs[i], hiddenInputsValues[i]);
    }

    if (this.hasManualSearch()) {
      this.revertManualSearchValues();
    }
  }

  saveManualSearchValues() {
    for (let i = 0; i < this.manualInputs.length; i++) {
      manualSearchValues[i] = this.manualInputs[i].value;
    }
  }

  clearManualSearchValues() {
    for (let i = 0; i < this.manualInputs.length; i++) {
      this.setInputValue(this.manualInputs[i], '');
    }
  }

  revertManualSearchValues() {
    for (let i = 0; i < this.manualInputs.length; i++) {
      this.setInputValue(this.manualInputs[i], manualSearchValues[i]);
    }
  }

  isValidAddress() {
    return this.addressSearchTarget.dataset.validState === 'true';
  }

  isInModal() {
    return this.addressSearchTarget.dataset.isInModal === 'true';
  }

  isMobile() {
    return bpHelper.isMatching('md', true);
  }

  hasManualSearch() {
    return this.addressSearchTarget.dataset.hasManualSearch === 'true';
  }

  hasTopTitle() {
    return this.addressSearchTarget.dataset.hasTopTitle === 'true';
  }

  setInputValue(inputElement, value) {
    inputElement.value = value;
    inputElement.setAttribute('value', value);
  }

  revertInputPosition() {
    if (this.isMobile() || this.isInModal()) {
      $('.modal-header').show();
      this.animateInputSlideDown();
      // on enlève le focus, ce qui libère le clavier en mobile
      document.activeElement.blur();
      this.addressSearchTarget.classList.add('mt-3');
    }
    this.addressInputTarget.classList.remove('focused');
    this.closeListButtonTarget.classList.add('hide');
  }

  setListHeight() {
    // only in a modal
    const $input = $(this.addressSearchTarget);
    const $modal = $($input.closest('.modal-body'));
    const inputpaddingY = parseInt($input.css('padding-top')) + parseInt($input.css('padding-bottom'));
    const modalPaddingY = parseInt($modal.css('padding-top')) + parseInt($modal.css('padding-bottom'));

    return ($modal.height() + modalPaddingY) - ($input.height() + inputpaddingY);
  }

  changeListSize() {
    const modalPaddingTop = $(this.addressSearchTarget.closest('.modal-body')).css('padding-top');
    const addressSearchPadBottom = $(this.addressSearchTarget).css('padding-bottom');
    const addressSearchHeight = `${$(this.addressSearchTarget).height()}px`;

    // if in mobile (with/without modal)
    if (this.isMobile()) {
      if (this.hasTopTitle()) {
        this.resultList.style.top = `calc(100% + ${addressSearchPadBottom})`;
      } else {
        this.resultList.style.top = `calc(${addressSearchHeight} + ${addressSearchPadBottom})`;
      }
      this.resultList.style.height = `calc( 100vh - ${this.addressSearchTarget.offsetHeight}px)`;
      this.resultList.style.width = '100vw';
    } else if (this.isInModal() && bpHelper.isMatching('md')) {
      this.resultList.style.top = `calc(${addressSearchHeight} + ${addressSearchPadBottom} - ${modalPaddingTop})`;
      this.resultList.style.height = `calc(${this.setListHeight()}px + ${modalPaddingTop})`;
      this.resultList.style.width = `calc(${$(this.addressSearchTarget).width()}px + 40px)`;
    }
  }

  handleErrorMessage() {
    if (this.isValidAddress()) {
      const errorMessage = this.addressSearchTarget.querySelector('#invalid-address');
      this.addressInputTarget.classList.remove('is-invalid');
      errorMessage.classList.remove('d-block');
      this.addressSearchTarget.classList.remove('mb-5');
    } else {
      const errorMessage = this.addressSearchTarget.querySelector('#invalid-address');
      this.addressInputTarget.classList.add('is-invalid');
      errorMessage.classList.add('d-block');
      this.addressSearchTarget.classList.add('mb-5');
    }
  }
}
