import dayjs from 'dayjs';
import 'arrive/src/arrive';
import { dateShowLoader } from '../../components/loaders';

$.fn.datetimepicker = async function(options) {
  const context = this;
  await import('../../vendors/datepicker/datetimepicker_refactored');
  await import('../../vendors/datepicker/datetimepicker.fr');
  $.fn.datetimepicker.call(context, options);
  return context;
};
document.arrive('.page-action-header .date-picking-day', function(element) {
  const $element = $(element);
  $element.datetimepicker({
    format: 'yyyy-mm-dd',
    language: 'fr',
    autoclose: true,
    isInline: true,
    todayHighlight: false,
    minView: 2,
    maxView: 3,
    todayBtn: true,
    startView: 'year',
    pickerPosition: 'bottom-right',
    initialDate: dayjs().startOf('day').format('YYYY-MM-DD'),
  });
  $element.on('changeDate', function(ev) {
    dateShowLoader($element);
    if ($element.attr('data-no-redirect') === undefined) {
      const url = location.origin + location.pathname + '?date=' + dayjs(ev.date).format('YYYY-MM-DD');
      if ($element.parents('turbo-frame')[0]) {
        $element.parents('turbo-frame')[0].src = url;
      } else {
        Turbo.visit(url);
      }
    }
  });
});

document.arrive('.page-action-header .date-picking-month', function(element) {
  const $element = $(element);
  $element.datetimepicker({
    format: 'yyyy-mm-dd',
    language: 'fr',
    autoclose: true,
    isInline: true,
    todayHighlight: false,
    minView: 3,
    maxView: 3,
    todayBtn: true,
    startView: 'year',
    pickerPosition: 'bottom-right',
    initialDate: dayjs().startOf('day').format('YYYY-MM-DD'),
  });
  $element.on('changeDate', function(ev) {
    dateShowLoader($element);
    if ($element.attr('data-no-redirect') === undefined) {
      const url = location.origin + location.pathname + '?date=' + dayjs(ev.date).format('YYYY-MM-DD');
      if ($element.parents('turbo-frame')[0]) {
        $element.parents('turbo-frame')[0].src = url;
      } else {
        Turbo.visit(url);
      }
    }
  });
});

document.arrive('.date_picker_wrapper [data-provide="datepicker"]', function(element) {
  const $element = $(element);
  $element.datetimepicker({
    format: 'dd/mm/yyyy',
    language: 'fr',
    autoclose: true,
    todayHighlight: true,
    minView: 2,
    maxView: 2,
    pickerPosition: $element.data().position ? $element.data().position : 'bottom-right',
  });
  $element.on('changeDate', function() {
    if ('addChangeEvent' in this.dataset && this.dataset.addChangeEvent === 'true') {
      // trigger change event when date is changed
      const event = new Event('change');
      this.dispatchEvent(event);
    }
  });
});

document.arrive('.date_picker_wrapper [data-provide="datetimepicker"]', function(element) {
  const $element = $(element);
  $element.datetimepicker({
    format: $element.data().format,
    language: 'fr',
    autoclose: true,
    todayHighlight: true,
    minView: 0,
    maxView: 2,
    minuteStep: $element.data()['minute-step'],
    pickerPosition: $element.data().position ? $element.data().position : 'bottom-right',
  });
});

document.arrive('.date_picker_wrapper [data-provide="yearfirstdatepicker"]', function(element) {
  const $element = $(element);
  const item = $element.datetimepicker({
    startView: 'decade',
    format: 'dd/mm/yyyy',
    language: 'fr',
    todayHighlight: false,
    autoclose: true,
    todayBtn: false,
    minView: 2,
    maxView: 3,
    pickerPosition: $element.data().position ? $element.data().position : 'bottom-right',
  });
  $element.on('changeDate', function(e) {
    // trigger change event when date is changed
    const event = new Event('change');
    this.dispatchEvent(event);
  });
  if (item instanceof Promise) {
    item.then((obj) => {
      obj.data('datetimepicker').picker.attr('id', 'datetimepicker_' + $element.attr('id'));
    });
  } else {
    item.data('datetimepicker').picker.attr('id', 'datetimepicker_' + $element.attr('id'));
  }
});
document.arrive('.date_picker_wrapper .remove_input_date_picker', function(element) {
  const $element = $(element);
  $element.on('click', function() {
    const input = $(this).parents('.date_picker_wrapper').find('input');
    input.val('');
    input.trigger('changeDate');
  });
});

document.arrive('.date_picker_wrapper .calendar-icon', function(element) {
  const $element = $(element);
  $element.on('click', function() {
    $(this).parents('.date_picker_wrapper').find('input').click();
  });
});
