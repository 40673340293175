import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'toggleInput',
    'toggleIllustrationInput',
  ];

  connect() {
    this.changeToggleText();
    this.changeToggleImgBg();
  }

  changeToggleText() {
    if (this.hasToggleInputTarget) {
      this.setToggleText();
    }
  }

  setToggleText() {
    const toggleData = this.toggleInputTarget.dataset;
    const text = this.toggleInputTarget.checked ? toggleData.trueText : toggleData.falseText;
    this.toggleInputTarget.style.setProperty('--form-toggle-after', `'${text}'`);
  }

  toggleIllustrationActiveClass() {
    const activeClass = 'toggle-illustration--checked';
    this.toggleIllustrationInputTarget.checked ? this.element.classList.add(activeClass) : this.element.classList.remove(activeClass);
  }

  changeToggleImgBg() {
    const canChangeBgImg = this.element.querySelector('.toggle-illustration__toggle-image');
    if (canChangeBgImg) this.toggleIllustrationActiveClass();
  }
}
