$.fn.isInViewport = function(topMargin = -1) {
  const elementTop = $(this).offset().top;
  const elementHeight = $(this).outerHeight();
  const elementBottom = elementTop + elementHeight + 5;
  const viewportTop = $(window).scrollTop();
  const viewportHeight = $(window).height();
  const viewportBottom = viewportTop + viewportHeight;
  if (topMargin === -1) {
    if (elementHeight < viewportHeight) {
      return elementBottom < viewportBottom && elementTop > viewportTop;
    } else {
      return elementBottom > viewportTop && elementTop < viewportBottom - 64;
    }
  } else {
    if (elementHeight >= viewportHeight) {
      return elementBottom > viewportTop && elementTop < viewportBottom - 64;
    }
    return elementTop > viewportTop + topMargin && elementTop < viewportTop + topMargin + 1;
  }
};
