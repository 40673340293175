document.addEventListener('turbo:load', function() {
  // Initialiser la position précédente du scroll à 0
  let previousScrollPosition = 0;
  const body = document.querySelector('body');

  // Obtenir l'élément qui a la barre de défilement
  const scrollElement = body.classList.contains('webapp') ? document.getElementById('page-content') : window;

  // Ajouter un écouteur d'évènement scroll à l'élément
  scrollElement.onscroll = function() {
    // Obtenir la position actuelle du scroll
    const currentScrollPosition = scrollElement instanceof Window ? window.pageYOffset : scrollElement.scrollTop;

    // Si l'utilisateur a scrollé vers le bas d'au moins 100px, cacher la barre de navigation
    if (currentScrollPosition > previousScrollPosition && currentScrollPosition > 150) {
      body.classList.add('scrolled-bottom');

    // Sinon, si l'utilisateur remonte, afficher la barre de navigation
    } else if (currentScrollPosition < previousScrollPosition) {
      body.classList.remove('scrolled-bottom');
    }

    // Mettre à jour la position précédente du scroll pour la prochaine fois
    previousScrollPosition = currentScrollPosition;
  };
});

document.addEventListener('turbo:before-cache', function() {
  document.querySelector('body').classList.remove('scrolled-bottom');
});
