import { Application } from '@hotwired/stimulus';

const application = Application.start();

// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;

Stimulus.getControllersForIdentifier = function(identifier) {
  return Stimulus.router.modulesByIdentifier.get(identifier).contexts.map((context) => { return context.controller; });
};

export { application };
