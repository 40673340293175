
const initializeShowPassword = function(element) {
  // console.info('input pass :${element}')
  const $icon = $(element).parents('.input-group-text');
  // create icon eye on input
  const $item = $icon.parents('.input-group').find('input[type="password"]');
  const togglePassword = function(e) {
    if ($item.attr('type') === 'password') {
      $item.attr('type', 'text');
    } else {
      $item.attr('type', 'password');
    }
  };
  $icon.off('click', togglePassword);
  $icon.on('click', togglePassword);
};

document.arrive('.password .input-group .showpass', initializeShowPassword);
