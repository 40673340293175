
document.addEventListener('turbo:load', function() {
  $(document).on('click', '.dotslink-blinker', function() {
    clickDotslinkBlinkerAction($(this));
  });
  $(document).on('click', '.dotslink-opener', function() {
    clickDotslinkOpenerAction($(this));
  });
});

function clickDotslinkBlinkerAction($element) {
  const $parent = $element.parent();
  if (typeof $parent !== 'undefined') {
    const $element = $parent.find('.dotslink');
    if (typeof $element !== 'undefined') {
      $element.removeClass('animate');
      $element.addClass('animate');
      $element.on('animationend', function() {
        $element.removeClass('animate');
      });
    }
  }
}

function clickDotslinkOpenerAction($element) {
  const $parent = $element.parent();
  if (typeof $parent !== 'undefined') {
    const $element = $parent.find('.dotslink');
    if (typeof $element !== 'undefined') {
      $element.click();
    }
  }
}
