import { Controller } from '@hotwired/stimulus';

// data-controller="form--profile"
export default class extends Controller {
  static targets = [
    'alert',
    'input',
  ];

  initialize() {
    if (this.hasAlertTarget) {
      this.toggle_alert_visibility();
    }
  }

  // called when modifying the value of first_name/last_name input
  modify_identity(e) {
    if (this.hasAlertTarget) {
      this.toggle_alert_visibility();
    }
  }

  toggle_alert_visibility() {
    // determine if alerts should be shown (if any first_name or last_name input is modified)
    const shouldHideAlert = this.inputTargets.every((element, index) => {
      const originalValue = element.getAttribute('data-original-value');
      const currentValue = element.value;
      const valueDelta = currentValue.localeCompare(originalValue, 'en', { sensitivity: 'base' });
      return (valueDelta === 0);
    });
    // toggle alerts visibility
    this.alertTargets.forEach((element, index) => {
      element.hidden = shouldHideAlert;
    });
  }
}
