// add here keywords to find in style sheet to remove ALL the stylesheet
const forbiddenWords = ['capitalkoala', 'ENOE_TOOLBAR_CONTAINER'];

function checkRules(styleSheet) {
  if (!styleSheet.ownerNode) return true;
  const content = styleSheet.ownerNode.innerHTML;
  for (const keyword of forbiddenWords) {
    if (content.indexOf(keyword) >= 0) {
      return false;
    }
  }
  return true;
}

function cleanRules(styleSheet) {
  if (checkRules(styleSheet)) {
    return;
  }
  console.log('delete rules needed');
  styleSheet.ownerNode.remove();
}

function cleanCssKoala() {
  for (const styleSheet of document.styleSheets) {
    // ca prend du temps alors on envoie décalé
    setTimeout(() => {
      cleanRules(styleSheet);
    }, 1);
  }
}

function cleanCapitalKoala() {
  const cleanItem = (item) => {
    item.remove();
    cleanCssKoala();
  };

  // 5 div s'ajoutent juste au debut du body
  document.arrive('body > #ENOE_TOOLBAR_CONTAINER', { existing: true }, cleanItem);
  document.arrive('body > #ENOE_TOOLBAR_CONTAINER_MAX', { existing: true }, cleanItem);
  document.arrive('body > .container_toolbar', { existing: true }, cleanItem);
}

cleanCapitalKoala();
