import 'arrive/src/arrive';

/**
 * L'affichage d'une modal cache les popover existants, sinon ils restent au dessus
 * Ne pas essayer de les mettre en dessous => crée trop de complexité dans le cas ensuite de popover devant être à l'intérieur d'une modale
 *
 */
document.addEventListener('show.bs.modal', (event) => {
  $('.popover').hide();
  // autre souci : l'ouvertue d'une modal sur mobile oblige à rescroller vers le haut, qd elle est appelée d'un contenu placé en bas
});

// tooltips inside of modal
document.addEventListener('turbo:load', function() {
  $('body').on('click', '.modal-tooltip-icon', function() {
    const contentId = $(this).data('hint-content-id');
    $(`#${contentId}`).slideToggle();
  });
});

document.arrive('.modal .popup-link-close', function(element) {
  const closeModal = function(event) {
    hideModal($(event.target).parents('.modal').attr('id'));
  };
  $(element).off('click', closeModal);
  $(element).on('click', closeModal);
});

/**
 * @param modalTemplate {string} Escaped javascript html or text, e.g. j render 'template'
 * @param id {string}
 */
const showModalAjax = function(modalTemplate, id, refreshOnShow = true) {
  const $modalObject = $(`#${id}`);
  if ($modalObject.length) {
    if (refreshOnShow) {
      const modalWrap = new DOMParser().parseFromString(modalTemplate, 'text/html');
      const body = modalWrap.querySelector('.modal-body').innerHTML;
      $(`#${id} .modal-body`).html(body);
    }
    try {
      const modalController = Stimulus.getControllerForElementAndIdentifier($modalObject[0], 'modal');
      modalController.show();
    } catch (e) {
      console.error(e);
    }
  } else {
    $('#modal-list').append(modalTemplate);
  }
};

/**
 * Remove modal the bad way
 * DO NOT USE ELSE ABSOLUTELY NECESSARY
 */
const hideModalManually = function(id) {
  const modal = document.getElementById(id);
  if (modal) {
    const body = document.body;
    modal.remove();
    body.style.removeProperty('overflow');
    body.style.removeProperty('padding-right');
    document.getElementsByClassName('modal-backdrop')[0].remove();
  }
};

const hideModal = function(id) {
  const element = document.getElementById(id);
  if (!element) {
    return;
  }
  try {
    const modalController = Stimulus.getControllerForElementAndIdentifier(element, 'modal');
    if (modalController) {
      modalController.hide();
    }
  } catch (e) {
    console.error(e);
  }
};

const closeAllModal = function() {
  Stimulus.getControllersForIdentifier('modal').forEach((controller) => {
    controller.hide();
  });
};
const openRemoteModal = function(url, level = '') {
  const modalFrame = document.querySelector('turbo-frame#remote_modal' + level);
  if (modalFrame) {
    modalFrame.src = url;
    modalFrame.reload();
  }
};
function isMobileOrTablet() {
  return /Mobi|Tablet|iPad|iPhone|Android/i.test(navigator.userAgent);
}
function isSmallScreen() {
  return window.innerWidth <= 768;
}
document.arrive('a[data-turbo-frame="remote_modal_mobile"]', function() {
  const element = this;
  if (isMobileOrTablet() || isSmallScreen()) {
    element.setAttribute('data-turbo-frame', 'remote_modal');
  } else {
    element.setAttribute('data-turbo-frame', '_top');
  }
});

export { showModalAjax, hideModalManually, closeAllModal, openRemoteModal, hideModal };
