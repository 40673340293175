document.arrive('.magicwand-complete-action', function(element) {
  // console.log('init magicwand');
  const $element = $(element);
  $element.off('click', magicWand);
  $element.on('click', magicWand);
});

function magicWand(event) {
  $('.complete-action.with-loader').addClass('loading');
  setTimeout(function() {
    $('.complete-action.with-loader').removeClass('loading');
  }, 5000);
};
