const truncateDecimals = function(number, digits) {
  const multiplier = Math.pow(10, digits);
  const adjustedNum = number * multiplier;
  const truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum);

  return truncatedNum / multiplier;
};
const updateField = function(event) {
  let validRegex;
  let regex;
  const $input = $(event.target);
  const numberAfterDecimal = $input.data('precision') || 2;

  // auto comas replacment
  let currValue = $input.val().trim();
  $input.val(currValue.replaceAll(/[.;?:/=+'n]/g, ',').replace(/,+/, ',').replace(/-+/, '-'));

  // forbidden caracters
  currValue = $input.val() || '0';

  if ($input.hasClass('allow-negative')) {
    regex = /[^0-9,-]/g;
    validRegex = /(^-?\d+(?:,?\d*))([^\d].*)?$/g;
  } else {
    regex = /[^0-9,]/g;
    validRegex = /(^\d+(?:,?\d*))([^\d].*)?$/g;
  }
  const m = validRegex.exec(currValue);
  if (currValue.search(regex) !== -1 || (m && m[0] && m[0][2])) {
    $input.val(currValue.replaceAll(regex, '').replaceAll(validRegex, '$1'));
    if ($input.parent().hasClass('input-group')) {
      $input.parent().addClass('is-invalid');
    } else {
      $input.addClass('is-invalid');
    }
    setTimeout(function() {
      if ($input.parent().hasClass('input-group')) {
        $input.parent().removeClass('is-invalid');
      } else {
        $input.removeClass('is-invalid');
      }
    }, 300);
  }

  // limit x decimal
  currValue = $input.val();
  if (currValue.match(/,/)) {
    if (currValue.split(/,/g)[1].length > numberAfterDecimal) {
      const number = parseFloat(currValue.replace(',', '.'));
      if (isNaN(number)) return;
      $input.val(truncateDecimals(number, numberAfterDecimal).toFixed(numberAfterDecimal).replace('.', ','));
    }
  }
};
const delayedUpdate = function(event) {
  setTimeout(updateField, 0, event);
};
const initializeInputOnlyDecimal = function(element) {
  const $input = $(element);
  const numberAfterDecimal = $input.data('precision') || 2;

  if ($input.val() !== '') {
    let inputVal = parseFloat($input.val().replace(',', '.')).toFixed(numberAfterDecimal);
    if ($input.hasClass('money-input')) {
      inputVal = inputVal.replace('.', ',');
    } else {
      // on retire les zéro à la fin si le champs n'est pas un champs de type €
      inputVal = String(parseFloat(inputVal)).replace('.', ',');
    }
    $input.val(inputVal);
  }

  $input.off('paste keyup change', delayedUpdate);
  $input.on('paste keyup change', delayedUpdate);
};

document.arrive('input.only-decimal', initializeInputOnlyDecimal);
