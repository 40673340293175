import 'arrive/src/arrive';

const initAutoTrim = function(element) {
  const $item = $(element);
  if ($item.hasClass('no_autotrim')) {
    return;
  }
  const autoTrim = function() {
    $item.val($item.val().trim());
  };
  $item.off('focusout', autoTrim);
  $item.on('focusout', autoTrim);
};

document.arrive('input[type="string"], input[type="text"], input[type="number"], input[type="email"]', initAutoTrim);
