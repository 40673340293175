$(document).on('turbo:load', function() {
  const $familyText = $('.contract-notebook__add-child .family');
  const $sharedText = $('.contract-notebook__add-child .shared');

  const showFamilyText = () => {
    $sharedText.hide();
    $familyText.show();
  };

  const showSharedText = () => {
    $familyText.hide();
    $sharedText.show();
  };

  if ($('.contract-notebook__ged-type:checked').val() === 'shared') {
    showSharedText();
  }

  $('.contract-notebook__ged-type').click(function() {
    const getTypeValue = $(this).val();
    if (getTypeValue === 'shared' && !$sharedText.is('visible')) {
      showSharedText();
    } else if (getTypeValue === 'simple' && !$familyText.is('visible')) {
      showFamilyText();
    }
  });
});
