import { Controller } from '@hotwired/stimulus';
import { bpHelper } from '../../components/utils';
import { Turbo } from '@hotwired/turbo-rails';

const mapId = 'search-map__inner';

export default class extends Controller {
  connect() {
    this.listingsCoordinates = null;
    this.apiKey = null;
    this.searchLocation = null;

    this.setMapParams();
    // we need the timeout as the javascript that works on the display mode radio is also loaded at load, so we need
    // to wait for it to be done to do our script
    setTimeout(() => {
      this.renderSearchMap();
    }, 500);
  }

  async mapAdInfo(marker, searchLocation) {
    const params = new URLSearchParams();
    params.append('search[adId]', marker.options.adId);
    params.append('search[adType]', marker.options.adType);
    params.append('search[lat]', searchLocation.lat);
    params.append('search[lon]', searchLocation.lon);

    return await fetch('/search/ad', {
      method: 'POST',
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-CSRF-Token': document.querySelector('[name=\'csrf-token\']').content,
      },
      body: params,
    });
  }

  initMarketplaceMap() {
    const mapContainer = document.querySelector('.search-map');

    mapContainer.innerHTML = '';
    mapContainer.innerHTML = `<div id='${mapId}' style='width: 100%; height: 100%;'></div>`;

    const listings = this.listingsCoordinates;
    const searchLocation = this.searchLocation;
    const position = new L.LatLng(searchLocation.lat, searchLocation.lon);
    const controller = this;

    const streets = L.tileLayer.Unwired({ key: this.apiKey, scheme: 'streets' });
    const map = L.map(mapId, {
      center: position,
      zoom: 10,
      maxZoom: 18,
      minZoom: 4,
      zoomSnap: 0.1,
      layers: [streets],
    });

    L.control.scale({ imperial: false }).addTo(map);

    const markerClusterer = L.markerClusterGroup({ zoomToBoundsOnClick: true, maxClusterRadius: 15 });
    const markers = [];

    const searchLocationIcon = L.divIcon({
      className: 'search-address-marker',
      html: `<div class="search-address-waves"><div class="search-address-waves__wave1"></div><div class="search-address-waves__wave2"></div></div><div class="search-address-marker__icon"><img src=${this.element.dataset.searchMarkerPath}></div>`,
      iconSize: [0, 0],
    });
    const searchLocationMarker = L.marker(position, { icon: searchLocationIcon, zIndexOffset: 10000 }).addTo(map);
    markers.push(searchLocationMarker);

    listings.forEach(function(ad) {
      const marker = L.marker(
        new L.LatLng(ad.lat, ad.lon),
        {
          icon: L.icon({
            iconUrl: ad.icon,
            iconSize: ad.iconSize === 'large' ? [48, 58] : [40, 48],
          }),
          adId: ad.id,
          adType: ad.type,
          latitude: ad.lat,
          longitude: ad.lon,
          riseOnHover: true,
          riseOffset: 250,
          zIndexOffset: (ad.iconSize === 'large' ? 1000 : 0),
        }
      );
      markers.push(marker);
      markerClusterer.addLayer(marker);
      marker.on('click', async function() {
        const that = this;
        // already initialized
        if (that._popup && that._popup._initHooksCalled && bpHelper.isMatching('md', false)) {
          return;
        }
        controller.mapAdInfo(this, searchLocation).then(function(response) {
          return response.text();
        }).then(function(html) {
          if (html.indexOf('search-result-item') > 0) {
            if (bpHelper.isMatching('md', true)) {
              document.querySelector('#display-content-offcanvas-bottom .offcanvas-body').innerHTML = html;
              $('#display-content-offcanvas-bottom').offcanvas('show');
            } else {
              that.bindPopup(html, { offset: L.point(0, -10), maxWidth: 400, minWidth: 400 }).openPopup();
            }
          } else {
            Turbo.renderStreamMessage(html);
          }
        });
      });
    });

    map.addLayer(markerClusterer);
    const group = new L.featureGroup(markers);
    if (markers.length > 0) {
      map.fitBounds(group.getBounds().pad(0.2));
    }
  }

  renderSearchMap() {
    const $displayModeSwitch = $('.search__display-mode');
    const displayMode = $('.search__display-mode:checked').val();
    let searchMapLoaded = false;
    const that = this;

    if (displayMode === 'map' && !searchMapLoaded) {
      that.initMarketplaceMap();
    } else {
      $displayModeSwitch.change(function() {
        if (this.value === 'map' && !searchMapLoaded) {
          searchMapLoaded = true;
          setTimeout(function() {
            that.initMarketplaceMap();
          }, 50);
        }
      });
    }
  }

  setMapParams() {
    this.listingsCoordinates = JSON.parse(this.element.dataset.rawListingsCoordinates);
    this.apiKey = this.element.dataset.apiKey;
    this.searchLocation = JSON.parse(this.element.dataset.rawSearchLocation);
  }
}
