import { Controller } from '@hotwired/stimulus';

const categoryActiveClass = 'category-search__category-list-item--selected';
const consultationCounterClass = 'consultation__count';

export default class extends Controller {
  static targets = [
    'categoryConsultation',
  ];

  static values = {
    category: String,
  };

  emptyContentValue = $('.empty-consultation');
  allList = $('.consultation-list');

  connect() {
    const counterTextElement = this.element.querySelectorAll('.' + consultationCounterClass);
    this.countResults(counterTextElement);
  }

  updateCategory({ detail: { category } }) {
    if (this.categoryValue === category) return;
    this.categoryValue = category;
    this.removeCategoryActiveClass();
    this.addCategoryActiveClass(category);
    this.activeCategoryContent(category);
  }

  transitionCategoryConsultation(event) {
    const element = event.target;
    if (element.classList.contains(categoryActiveClass)) return false;
    this.updateCategory({ detail: { category: event.params.category } });
  }

  removeCategoryActiveClass() {
    this.categoryConsultationTargets.forEach(target => {
      target.classList.remove(categoryActiveClass);
    });
  }

  addCategoryActiveClass(category) {
    $(`.category-search__category-list-item-${category}`).addClass(categoryActiveClass);
  }

  activeCategoryContent(category) {
    const $consultListContainer = $('.list-' + category);
    const $item = $('.consultation-item-' + category);
    const number = $item.length;
    this.allList.addClass('d-none');
    if (number > 0) {
      this.emptyContentValue.addClass('d-none');
      $consultListContainer.removeClass('d-none');
    } else {
      this.emptyContentValue.removeClass('d-none');
    }
  }

  countResults(counterElement) {
    counterElement.forEach(text => {
      const category = text.dataset.category;
      const consultationNumber = document.querySelectorAll('.consultation-item-' + category).length;
      text.textContent = consultationNumber;
      this.verifyFirstCat(category, consultationNumber);
    });
  }

  verifyFirstCat(category, number) {
    if (category === 'child' && number === 0) {
      this.emptyContentValue.removeClass('d-none');
    }
  }
}
