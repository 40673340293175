import { Controller } from '@hotwired/stimulus';
import { otherRadioButtonsWithName, showHideAssociatedSelector } from '../../form/input';
import bsModal from 'bootstrap/js/dist/modal';

const activeClass = 'input-card-illustration--selected';
const wrapperClass = 'input-card-illustration';

export default class extends Controller {
  static targets = [
    'input',
    'validationContent',
    'validate',
    'cancel',
  ];

  connect() {
    this.addActiveClass();
    if (this.inputTarget.type === 'checkbox' || this.inputTarget.type === 'radio') {
      showHideAssociatedSelector(this.inputTarget);
    }
  }

  radioSelect(event) {
    this.inputTarget.checked = true;
    this.toggleRadioActiveClass();
    showHideAssociatedSelector(this.inputTarget);
  }

  /**
   * used for the checkbox only
   * @param event
   */
  toggleSelect(event) {
    if (!this.hasValidationContentTarget || this.isChecked()) {
      if (event.target !== this.inputTarget) {
        this.inputTarget.checked = !this.isChecked();
      }
      this.toggleActiveClass();
      showHideAssociatedSelector(this.inputTarget);
    } else {
      this.showValidationContent();
    }
  }

  checkCheckbox() {
    this.inputTarget.checked = true;
    this.toggleActiveClass();
    showHideAssociatedSelector(this.inputTarget);
  }

  addActiveClass() {
    if (this.isChecked()) {
      this.element.classList.add(activeClass);
    }
  }

  toggleRadioActiveClass() {
    otherRadioButtonsWithName(this.inputTarget.name).forEach((radio) => {
      if (radio !== this.inputTarget) {
        radio.checked = false;
        showHideAssociatedSelector(radio);
      }
      radio.closest(`.${wrapperClass}`).classList.remove(activeClass);
    });
    this.addActiveClass();
  }

  toggleActiveClass() {
    this.isChecked() ? this.element.classList.add(activeClass) : this.element.classList.remove(activeClass);
  }

  isChecked() {
    return this.inputTarget.checked;
  }

  showValidationContent() {
    if (!this.isChecked()) {
      this.modal = bsModal.getOrCreateInstance(this.validationContentTarget);
      this.modal.show();
    }
  }

  cancelCheck() {
    this.inputTarget.checked = false;
    this.modal.hide();
  }

  validateCheck() {
    this.checkCheckbox();
    this.modal.hide();
  }
}
