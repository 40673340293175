import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['subcategorySelect', 'background'];

  connect() {
    this.selectVisibleCategory();
    this.subCategoriesByDefault();
  }

  transitionCategories(event, firsLoad = false) {
    const category = event.params.category;

    // Show / hide subcategories
    const $selectOptions = $('.select-icon__option');
    $selectOptions.removeClass('select-icon__first-visible select-icon__last-visible');
    $(`.select-icon__option.${category}`).removeClass('select-icon__option--hidden').addClass('option-visible');
    $selectOptions.not(`.${category}`).removeClass('option-visible select-icon__first-visible select-icon__last-visible').addClass('select-icon__option--hidden');
    const firstOption = $('.select-icon__option.option-visible:first');
    const lastOption = $('.select-icon__option.option-visible:last');
    firstOption.addClass('select-icon__first-visible');
    lastOption.addClass('select-icon__last-visible');

    const value = firstOption.data('select-icon-value-param');
    const text = firstOption.data('select-icon-text-param');
    const icon = firstOption.data('select-icon-icon-param');
    const selectIconController = this.application.getControllerForElementAndIdentifier(this.subcategorySelectTarget, 'select-icon');
    const mobileView = typeof event.params.mobile !== 'undefined';

    /**
     * Hide the category and change the image and title
     */
    if (mobileView) {
      const $element = $(event.target);
      $element
        .parents('.radio-button-card')
        .children('.radio-button-card__input-wrapper')
        .css('display', 'initial');

      $element.parent('.radio-button-card__input-wrapper').css('display', 'none');
      $('.category-search__mobile-header .image img').fadeOut(400, function() {
        $(this).attr('src', event.params.badge).on('load', function() {
          $(this).fadeIn(400);
        });
      });

      $('.category-search__mobile-header .title').html(event.params.title);
    }

    if (!firsLoad) {
      $(this.backgroundTarget).fadeTo('slow', 0, function() {
        const $element = $(this);
        $element.css('background-image', `url(${$element.data(`${category}Background`)})`);
      }).fadeTo('slow', 1);
    }

    // communicate with select icon controller to make it select option
    selectIconController.select({ params: { value, text, icon } });
  }

  subCategoriesByDefault() {
    // by default let only child subcategories show
    const category = $('.category-search__category:checked').val() || 'child';
    setTimeout(() => this.transitionCategories({ params: { category } }, true), 500);
  }

  selectVisibleCategory() {
    const selectedValue = $('.radio-button-card').find('input:checked').val();
    setTimeout(() => {
      $(`.radio-button-card input[value="${selectedValue}"]`).parent(':visible').click();
    }, 500);
  }

  typeLabelChange({ service, offer }) {
    // search-category-form__type-service
    // search-category-form__type-offer
  }
}
