import { Controller } from '@hotwired/stimulus';
import { showHideAssociatedSelector } from '../form/input';

export default class extends Controller {
  static targets = [
    'inputWrapper', // first parent of input
    'input',
  ];

  connect() { // disable click on label
    // don't use jquery for this event, it will not trigger buddled event
    const label = this.inputWrapperTarget.getElementsByTagName('label')[0];
    if (label) label.addEventListener('click', (e) => { e.preventDefault(); });
    this.inputTarget ||= this.element.querySelector("input[type='checkbox']");
    this.refreshCheckbox('connect');
  }

  // catch click on checkbox parent
  clickOnParent(e) {
    // if checkbox is hidden and the click is not directly made on it or its label
    // this is used for the checkbox button
    if (e.target !== this.inputTarget) {
      this.inputTarget.checked = !this.inputTarget.checked;
    }

    this.toggle();
    return false;
  }

  refreshCheckbox(status = 'none') {
    this.inputWrapperTarget.classList[this.inputTarget.checked ? 'add' : 'remove']('checked');

    if (this.inputTarget.checked) {
      this.inputTarget.setAttribute('checked', 'checked');
    } else {
      this.inputTarget.removeAttribute('checked');
    }

    showHideAssociatedSelector(this.inputTarget);

    // the call to another controller should not be done on connect as it may not be instantiated
    // this code is use to change the toggle text
    if (status !== 'connect' && this.element.dataset.controller.includes('form--toggle')) {
      const formToggleController = this.application.getControllerForElementAndIdentifier(this.element, 'form--toggle');
      formToggleController.changeToggleText();
    }
  }

  toggle() {
    this.inputTarget.dispatchEvent(new Event('input_change'));
    this.refreshCheckbox();
    if (!!this.inputTarget.form && this.inputTarget.form.dataset.autosubmit) this.inputTarget.form.requestSubmit();
  }
}
