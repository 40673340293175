import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['selectedInput', 'selectedButton', 'optionsList'];
  static values = {
    selected: { type: String, default: '|-1|' },
  };

  connect() {
    this.selectedValue = this.selectedInputTarget.value;
  }

  selectOption(event) {
    this.selectedValue = event.target.getAttribute('data-value');
    if (this.selectedInputTarget.form.dataset.autosubmit) this.selectedInputTarget.form.requestSubmit();
  }

  selectedValueChanged() {
    if (this.selectedValue === '|-1|') return;
    this.optionsListTarget.querySelectorAll('[data-value].active').forEach((item) => { item.classList.remove('active'); });
    const selected = this.optionsListTarget.querySelector(`[data-value="${this.selectedValue}"]`);
    if (selected) selected.classList.add('active');
    this.selectedButtonTarget.innerHTML = selected ? selected.innerHTML : this.selectedButtonTarget.getAttribute('data-placeholder');
    this.selectedInputTarget.value = this.selectedValue;

    const _selectValue = this.selectedValue;
    const items = this.optionsListTarget.querySelectorAll('[data-value]');
    const anychecked = !!selected;
    items.forEach(function(item) {
      const metaDatas = item.dataset;
      const uncheckedSelector = metaDatas.unchecked_selector_display;
      const checkedSelector = metaDatas.checked_selector_display;
      const undefinedSelector = metaDatas.undefined_selector_display;
      const definedSelector = metaDatas.defined_selector_display;
      if (undefinedSelector) $(undefinedSelector).toggle(!anychecked);
      if (definedSelector) $(definedSelector).toggle(anychecked);
      if (uncheckedSelector) $(uncheckedSelector).hide();
      if (checkedSelector) $(checkedSelector).hide();
    });

    items.forEach(function(item) {
      const metaDatas = item.dataset;
      const checkedSelector = metaDatas.checked_selector_display;
      const uncheckedSelector = metaDatas.unchecked_selector_display;

      if (anychecked) {
        if (metaDatas.value === _selectValue) {
          if (checkedSelector) $(checkedSelector).show();
        } else {
          if (uncheckedSelector) $(uncheckedSelector).show();
        }
      }
    });
  }
}
