import 'arrive/src/arrive';

(function($) {
  $.fn.enterAsTab = function(options) {
    const settings = $.extend({
      allowSubmit: false,
    }, options);
    const onKeyDownListener = function(event) {
      if (settings.allowSubmit) {
        const type = $(this).attr('type');
        if (type === 'submit') {
          return true;
        }
      }
      // console.log("enterAsTab key : "+event.keyCode);

      // enter or tab
      if (event.keyCode === 13 || (event.keyCode === 9 && !event.shiftKey)) {
        const inputs = $(this).parents('form').eq(0).find(':input:visible:not(:disabled)').filter(function() {
          const $that = $(this);
          if ($that.attr('readonly')) {
            return ($that.filter('input[type=year_first_date_picker], input[type=datetime_picker], input[type=date_picker]').length > 0);
          }
          return true;
        });
        // console.log("enterAsTab find next");
        // console.log(inputs);
        let idx = inputs.index(this);
        if (idx === inputs.length - 1) {
          idx = -1;
        } else {
          inputs[idx + 1].focus();
          $(inputs[idx + 1]).focusin(); // handles submit buttons
        }
        try {
          inputs[idx + 1].select();
        } catch (err) {
          // handle objects not offering select
        }
        return false;
      }
    };
    $(this).find('input, select, button').off('keydown', onKeyDownListener);
    $(this).find('input, select, button').on('keydown', onKeyDownListener);
    return this;
  };
})(jQuery);
$('form:not(.allow-submit-enter)').enterAsTab({ allowSubmit: false });
$('form.allow-submit-enter').enterAsTab({ allowSubmit: true });
document.arrive('form', function(form) {
  $(form).enterAsTab({ allowSubmit: form.classList.contains('allow-submit-enter') });
});
