function setupTimeRanges(item) {
  const $item = $(item);
  const startsAt = $item.find('.item_time_range').first();
  const $startsAt = $(startsAt);
  const $startsAtHours = $startsAt.find('select').first();
  const $startsAtMinutes = $startsAt.find('select').last();
  const endsAt = $item.find('.item_time_range').last();
  const $endsAt = $(endsAt);
  const $endsAtHours = $endsAt.find('select').first();
  const $endsAtMinutes = $endsAt.find('select').last();

  if (!$item.hasClass('without-hour-check')) {
    const updateEndsAtOnChange = function() {
      if ($startsAtHours.val() > $endsAtHours.val()) {
        $endsAtHours.val($startsAtHours.val());
      } else if ($startsAtHours.val() === $endsAtHours.val() && $startsAtMinutes > $endsAtMinutes) {
        $endsAtMinutes.val($startsAtMinutes.val());
      }
    };
    $startsAt.off('change', updateEndsAtOnChange);
    $startsAt.on('change', updateEndsAtOnChange);

    const updateStartsAtOnChange = function() {
      if ($startsAtHours.val() > $endsAtHours.val()) {
        $startsAtHours.val($endsAtHours.val());
      } else if ($startsAtHours.val() === $endsAtHours.val() && $startsAtMinutes > $endsAtMinutes) {
        $startsAtMinutes.val($endsAtMinutes.val());
      }
    };

    $endsAt.off('change', updateStartsAtOnChange);
    $endsAt.on('change', updateStartsAtOnChange);
  }

  if ($endsAtHours.children('option').last().val() === '24') {
    const checkForMidNight = function() {
      // Pour empêcher de dépasser minuit (ex 24h15)
      if ($endsAtHours.val() === '24') {
        $endsAtMinutes.val('00');
      }
      // Pour empêcher de déclarer minuit par 00h00 et forcer 24h00
      if ($endsAtHours.val() === '00' && $endsAtMinutes.val() === '00') {
        $endsAtHours.val('24');
      }
    };
    $endsAt.off('change', checkForMidNight);
    $endsAt.on('change', checkForMidNight);
  }
};

document.arrive('form .time_range_wrapper', function(item) {
  setTimeout(function() {
    setupTimeRanges(item);
  });
});
