import Popover from 'bootstrap/js/dist/popover';
import { OffCanvasComponent } from '../../components/offcanvas';
import { bpHelper } from '../../components/utils';
import { popupLinkConfig } from '../../config/popup-link';
import { showModalAjax } from './modal';

function createPopupLink(activeClass, $link) {
  const title = $link.data('title');
  const body = $link.data('body');
  const customClass = $link.data('popover-class');
  const forcePopupPosition = $link.data('force-popup-position');
  const positionPopup = forcePopupPosition || $link.data('position-popup');
  const container = $link.data('container') || false;

  const tooltipClass = 'tooltip-element' + ' ' + customClass;
  $link.addClass(activeClass);
  const tooltip = new Popover(
    $link[0],
    {
      title: title || '',
      container,
      content: body,
      placement: positionPopup || 'left',
      fallbackPlacements: (forcePopupPosition ? [forcePopupPosition] : ['top', 'right', 'bottom', 'left']),
      html: true,
      trigger: 'manual',
      sanitize: false,
      customClass: tooltipClass,
      popperConfig: {
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              boundary: 'viewport',
              padding: 12,
            },
          },
          {
            name: 'flip',
            options: {
              boundary: 'viewport',
              fallbackPlacements: (forcePopupPosition ? [forcePopupPosition] : ['top', 'right', 'bottom']),
              allowedAutoPlacements: ['left', 'right', 'top', 'bottom'],
              padding: 12,
            },
          }],
      },
    }
  );
  tooltip.show();
  tooltip._element.addEventListener('hide.bs.popover', () => {
    $link.removeClass(activeClass);
    $(document).off('mouseup', documentMouseUp);
  }, { once: true });
  $(`.${popupLinkConfig.closeBtnClass}`).click(function() {
    tooltip.hide();
  });

  // Event to watch when user click outside of the opened popover

  function documentMouseUp(e) {
    const $container = $(`.${tooltipClass}`);

    // if the target of the click isn't the container nor a descendant of the container
    // and it is nor the element that we clicked that is targeted

    const clickOnModal = e.target.classList.contains('modal') || e.target.classList.contains('modal-backdrop');

    if (!clickOnModal && !$container.is(e.target) && $container.has(e.target).length === 0 && !$link.is(e.target) &&
        $link.has(e.target).length === 0) {
      tooltip.hide();
    } else {
      $(document).one('mouseup', documentMouseUp);
    }
  }

  $(document).one('mouseup', documentMouseUp);

  return tooltip;
}

function createOffcanvas($link) {
  const title = $link.data('title');
  const body = $link.data('body');
  const positionCanvas = $link.data('position-canvas');
  const customClass = $link.data('customClass');

  const canvas = new OffCanvasComponent(title, body, positionCanvas, customClass);
  canvas.show();
  const $parent = $link.parents('.modal.show').first();
  if ($parent.length > 0) {
    $(canvas.offcanvas._element).css('z-index', $parent.css('z-index') * 1 + 2);
    $(canvas.offcanvas._backdrop._element).css('z-index', $parent.css('z-index') * 1 + 1);
  } else {
    $(canvas.offcanvas._element).css('z-index', '');
    $(canvas.offcanvas._backdrop._element).css('z-index', '');
  }
  return canvas;
}

document.arrive('.display-content', function(element) {
  const activeClass = 'display-content--active';
  // attention, aussi utilisé dans application.js dans les règle de turbocache

  $(element).on('click', function() {
    const $link = $(this);
    if ($link.hasClass(activeClass)) {
      const openedPopover = Popover.getInstance(this);
      openedPopover.hide();
      $link.removeClass(activeClass);
      return false;
    }

    if ((bpHelper.isMatching('md', true) && $link.data('mobile-display') === 'canvas') || $link.data('computer-display') === 'canvas') {
      return createOffcanvas($link);
    } else if ((bpHelper.isMatching('md', true) && $link.data('mobile-display') === 'popover') ||
        (!bpHelper.isMatching('md', true) && $link.data('computer-display') === 'popover')) {
      createPopupLink(activeClass, $link);
    } else if ((bpHelper.isMatching('md', true) && $link.data('mobile-display') === 'modal') ||
        (!bpHelper.isMatching('md', true) && $link.data('computer-display') === 'modal')) {
      // ici créer et afficher une modale avec le même contenu que le popover ou le canvas
      showModalAjax($link.data('modal-content'), $link.data('modal-id'), $link.data('modal-refresh'));
    }
  });
});
